import { wrapUrls } from 'jsx/utils/urlWrapper';

const viewRoutes = {
  signup() {
    return '/signup' as const;
  },
  eligibility: {
    main() {
      return '/signup/eligibility/state' as const;
    },
    dateOfBirth() {
      return '/signup/eligibility/dateofbirth' as const;
    },
    state() {
      return '/signup/eligibility/state' as const;
    },
    guardian() {
      return '/sign-up/guardian' as const;
    },
  },
  emailVerification() {
    return '/signup/verification' as const;
  },
  name() {
    return '/signup/legal-name' as const;
  },
  questionnaire() {
    return `/signup/questionnaire` as const;
  },
  questionnaireQuestion(questionUuid: string): string {
    return `/signup/questionnaire/${questionUuid}` as const;
  },
  questionnaireOutcome(): string {
    return `/signup/questionnaire/outcome` as const;
  },
  accutaneNotice() {
    return '/signup/accutane-notice' as const;
  },
  pregnancyNotice() {
    return '/signup/pregnancy-notice' as const;
  },
  activationIntro() {
    return '/signup/activation-intro' as const;
  },
  productSelection() {
    return '/sign-up/get-started/skin/recommendations-and-checkout' as const;
  },
  smsVerification() {
    return '/signup/sms-verification' as const;
  },
  checkout() {
    return '/signup/checkout' as const;
  },
  customizeSubscription() {
    return '/signup/customize' as const;
  },
  ingredients() {
    return '/signup/set-ingredients' as const;
  },
  photosIntro() {
    return '/signup/photos-intro' as const;
  },
  photoUpload() {
    return '/signup/upload-photos' as const;
  },
  idUpload() {
    return '/signup/upload-id' as const;
  },
  liveConsultation() {
    return '/signup/schedule-consultation' as const;
  },
  done() {
    return '/signup/done' as const;
  },
  refTypeSurvey() {
    return '/signup/survey' as const;
  },
  dashboardHome() {
    return '/dashboard/home' as const;
  },
  upsell() {
    return '/signup/product-offer' as const;
  },
} as const;

const apiRoutes = {
  updateDateOfBirthAndState: 'api.patient.eligibility.dobAndStateUpdate',
  updateLegalName: 'app.users.self.legalName',
  completeSignup: 'api.signup.complete',
  activateTrial: 'app.patient.payment.activateTrial',
  activateExpressCheckoutTrial:
    'app.patient.payment.activateExpressCheckoutTrial',
  referrerDetails: 'api.patient.referrerDetails',
  idVerificationStatus: 'api.patient.idVerificationStatus',
  smsPreferences: 'api.patient.smsPreferences',
  updateAddress: 'app.patient.address',
  deletePaymentMethod: 'app.patient.payment.remove',
  subscriptionRecommendations: 'api.patient.subscriptionRecommendation',
  multipleSubscriptionRecommendations:
    'api.patient.subscriptionRecommendations',
  markSubscriptionRecommendationViewed:
    'api.patient.subscriptionRecommendation.markViewed.v2',
  markSubscriptionRecommendationsViewed:
    'api.patient.subscriptionRecommendations.markViewed.v2',
  sendEmailVerification: 'api.patient.sendVerificationEmail',
  sendPaymentPageLoadEventToTikTok: 'api.patient.tikTokClickId',
  upsells: 'api.patient.upsells',
  updateUpsell: 'api.patient.upsells.update',
  deletePatient: 'api.patient.delete',
} as const;

const URLS = { ...viewRoutes, api: wrapUrls(apiRoutes) } as const;

export default URLS;

import BaseItemModel, { BaseItemData } from 'jsx/models/baseItem';

export interface SubscriptionItemData extends BaseItemData {
  itemId: number;
  quantity: number;
  isDeferrable?: boolean;
  isDeferred?: boolean;
}

export default class SubscriptionItemModel extends BaseItemModel {
  constructor(data: SubscriptionItemData) {
    super(data);
    this.itemId = data.itemId;
    this.quantity = data.quantity;
    this.isDeferrable = data.isDeferrable ?? false;
    this.isDeferred = data.isDeferred ?? false;
  }

  readonly itemId: number;

  readonly quantity: number;

  readonly isDeferrable: boolean;

  readonly isDeferred: boolean;
}

import { computed, makeObservable } from 'mobx';

import { NUDGE_TAGS, NUDGE_TYPES } from 'jsx/constants/nudgeTags';

export interface NudgeData {
  cancels_at: string;
  created_at: string;
  deleted_at: string | null;
  id: number;
  nudge_tag_id: number | null;
  nudge_type_id: number;
  patient_user_id: number;
  updated_at: string;
}

export default class NudgeModel {
  constructor(data: NudgeData) {
    makeObservable(this);
    this.cancelsAt = data.cancels_at;
    this.createdAt = data.created_at;
    this.deletedAt = data.deleted_at;
    this.id = data.id;
    this.nudgeTagId = data.nudge_tag_id;
    this.nudgeTypeId = data.nudge_type_id;
    this.patientUserId = data.patient_user_id;
    this.updatedAt = data.updated_at;
  }

  cancelsAt: string;

  createdAt: string;

  deletedAt: string | null;

  id: number;

  nudgeTagId: number | null;

  nudgeTypeId: number;

  patientUserId: number;

  updatedAt: string;

  /**
   * We are expanding the functionality of the Dashboard live consultation scheduling.
   * We want to show more limited copy (e.g. no mention of trials, etc.) for patients who already completed signup
   * but then change their shipping address to a live consultation state, requiring a new (live) consultation.
   */
  @computed
  get isProviderRequiringPhoneConsultation() {
    return (
      this.nudgeTypeId === NUDGE_TYPES.needsPhoneConsultation.id &&
      (this.nudgeTagId === NUDGE_TAGS.phoneConsultationEstablishCare.id ||
        this.nudgeTagId === NUDGE_TAGS.phoneConsultationExternalMed.id)
    );
  }
}

import React from 'react';
import ReactDOM from 'react-dom';

export const applyAccessibilityChecks = async (renderApp: () => void) => {
  if (process.env.NODE_ENV !== 'production') {
    const { default: reactAxe } = await import('@axe-core/react');
    const { axeConfig, axeContext, DEBOUNCE_TIMEOUT } = await import(
      './config'
    );

    reactAxe(React, ReactDOM, DEBOUNCE_TIMEOUT, axeConfig, axeContext);
  }

  renderApp();
};

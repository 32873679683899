import React from 'react';
import { MobXProviderContext } from 'mobx-react';

/**
 * @see https://mobx-react.js.org/recipes-migration#hooks-to-the-rescue
 *
 * We set the generic to never to catch any `use*Store` definitions that
 * do not include typing for the return value. Any store that has a return
 * value will ignore the `never`, but if it is missing it will raise errors
 * in downstream usage.
 */
const useStores = <T = never>(): Record<string, T> =>
  React.useContext<Record<string, T>>(MobXProviderContext);

export default useStores;

import React, { EffectCallback } from 'react';

/**
 * We have some function component behavior that we want to behave
 * equivalently to "componentDidMount" class component behavior,
 * which means we only want the effect to run once. However, this
 * runs contrary to best practices around effect dependency arrays:
 * rather than add `eslint-disable-next-line` to those usages, we
 * can use `useEffectOnce` to clarify this intent.
 *
 * **Please be sure that your use-case is appropriate for running
 * effects only once!** It is likely that specifying your dependencies
 * is more appropriate than using this helper. This helper was added
 * for validating cached/stored data on mount and not re-running those
 * validations on every keystroke (which causes a re-render). Most
 * effects will run only once even when specifying dependencies, given
 * referential integrity is maintained.
 *
 * The callback will include any cleanup effects, if present
 */
export const useEffectOnce = (callback: EffectCallback) => {
  // eslint-disable-next-line -- This effect should only run once, regardless of dependencies
  React.useEffect(callback, []);
};

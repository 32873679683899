const CONFIG = {
  errorMessages: {
    generic:
      'Oops! We ran into an error and will look into it. Please try again.',
    network:
      'A network error occurred. Please make sure you are connected to the internet and try again.',
    server:
      'A server error has occurred. Please refresh your browser and try again.',
  },
  get env() {
    return window.CUROLOGY_ENV.APP_ENV;
  },
  get isProduction() {
    return window.CUROLOGY_ENV.APP_ENV === 'production';
  },
  get isTestEnv() {
    return window.CUROLOGY_ENV.APP_ENV === 'testing';
  },
  get appBrand() {
    return window.CUROLOGY_ENV.APP_BRAND;
  },
  get isAgencyApp() {
    return !!window.CUROLOGY_ENV && window.CUROLOGY_ENV.APP_BRAND === 'agency';
  },
  get isCurologyApp() {
    return (
      !!window.CUROLOGY_ENV && window.CUROLOGY_ENV.APP_BRAND === 'curology'
    );
  },
  get commitId() {
    return window.CUROLOGY_ENV.COMMIT_ID;
  },
  keys: {
    get datadogRumApplicationId() {
      return window.CUROLOGY_ENV.DATADOG_RUM_APPLICATION_ID;
    },
    get datadogRumClientToken() {
      return window.CUROLOGY_ENV.DATADOG_RUM_CLIENT_TOKEN;
    },
    get kustomerKey() {
      return window.CUROLOGY_ENV.KUSTOMER_FRONTEND_API_KEY;
    },
    get kustomerBrandId() {
      return window.CUROLOGY_ENV.KUSTOMER_BRAND_ID;
    },
    get pusherKey() {
      return window.CUROLOGY_ENV.PUSHER_KEY;
    },
    get sentryDsn() {
      return window.CUROLOGY_ENV.SENTRY_DSN;
    },
    get stripeKey() {
      return window.CUROLOGY_ENV.STRIPE_PUBLIC;
    },
    get recaptchaKey() {
      return window.CUROLOGY_ENV.RECAPTCHA_SITE_KEY;
    },
  },
  locations: {
    sdOffice: 'SD',
    pharmacyLocation: 'STL',
  },
  get gatsbyUrl() {
    return window.CUROLOGY_ENV.GATSBY_URL;
  },
  get gatsbyCookieDomain() {
    return window.CUROLOGY_ENV.GATSBY_COOKIE_DOMAIN;
  },
  logging: {
    get eventConsoleLogging() {
      return window.CUROLOGY_ENV.EVENT_CONSOLE_LOGGING === 'true';
    },
  },
} as const;

export default CONFIG;

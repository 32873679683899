import React from 'react';
import { Route as RouterRoute, RouteProps } from 'react-router-dom';

import DocumentTitle from 'jsx/components/documentTitle';

interface PageRouteProps extends RouteProps {
  component: React.ComponentType<any>;
  title: string;
}

const PageRoute = ({
  component: Component,
  title,
  ...rest
}: PageRouteProps) => (
  <RouterRoute
    render={(routeProps) => (
      <React.Fragment>
        <DocumentTitle>{title}</DocumentTitle>
        <Component {...routeProps} />
      </React.Fragment>
    )}
    {...rest}
  />
);

export default PageRoute;

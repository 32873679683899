/* eslint-disable sonarjs/no-duplicate-string */
import React, { useMemo } from 'react';
import {
  AzelaicAcidGlyph,
  CaffeineGlyph,
  CleanseGlyph,
  ClindamycinGlyph,
  CloggedPoresGlyph,
  DesonideGlyph,
  DexpanthenolGlyph,
  GlycerinGlyph,
  GreenTeaGlyph,
  HydrocortisoneGlyph,
  HydroquinoneGlyph,
  IvermectinGlyph,
  KetoconazoleGlyph,
  KojicAcidGlyph,
  MetronidazoleGlyph,
  NiacinamideGlyph,
  ResveratrolGlyph,
  SpironolactoneGlyph,
  TranexamicAcidGlyph,
  TretinoinGlyph,
  VitaminCGlyph,
  ZincGlyph,
} from 'radiance-ui/lib/icons';

import { getBrandedContent } from 'jsx/utils/brandedContent';
import errorReporter from 'jsx/utils/errorReporter';
import {
  TreatmentCategory,
  TREATMENT_CATEGORIES,
} from 'jsx/types/treatmentCategories';

/**
 * How to add a new ingredient:
 * 1. Add it to the INGREDIENTS list (required)
 * 2. Add a displayName to INGREDIENTS_DISPLAY_NAME_MAP_INTERNAL_USE_ONLY (required)
 * 3. Add a glyph to INGREDIENTS_ICON_MAP_INTERNAL_USE_ONLY if it exists.
 * 4. Add a description to INGREDIENTS_DESCRIPTION_ACNE_INTERNAL_USE_ONLY and INGREDIENTS_DESCRIPTION_ANTI_AGING_INTERNAL_USE_ONLY (required)
 * 5. Add a guideUrl to INGREDIENTS_GUIDE_URL_AGENCY_INTERNAL_USE_ONLY and INGREDIENTS_GUIDE_URL_CUROLOGY_INTERNAL_USE_ONLY if it exists.
 *
 * Do *NOT* import constants for descriptions etc. directly! Use `getIngredientInfo` or `useIngredientInfo` below!
 */
export const INGREDIENTS_FOR_SKIN = [
  'adenosine',
  'allantoin',
  'aloe',
  'aloeVera',
  'arganOil',
  'ascorbicAcid',
  'avobenzone',
  'azelaicAcid',
  'benzoylPeroxide',
  'betaCarotene',
  'caffeine',
  'carnaubaWax',
  'ceramides',
  'clindamycinPhosphate',
  'desonide',
  'dexpanthenol',
  'dimethicone',
  'ectoin',
  'finasteride',
  'glycerin',
  'glycolicAcid',
  'greenTeaExtractEpigallocatechinGallate',
  'hyaluronicAcid',
  'hyaluronicAcidPolyglutamicAcidComplex',
  'hydrocolloidMatrix',
  'hydrocortisone',
  'hydroquinone',
  'ivermectin',
  'jojobaSeedOil',
  'ketoconazole',
  'kojicAcid',
  'licoriceRootExtract',
  'linoleicAcid',
  'metronidazole',
  'niacinamide',
  'octisalate',
  'panthenol',
  'peptides',
  'petrolatum',
  'polyglutamicAcid',
  'resveratrol',
  'salicylicAcid',
  'sheaButter',
  'sodiumHyaluronate',
  'sodiumPca',
  'spironolactone',
  'squalane',
  'sulfacetamideSodium',
  'titaniumDioxide',
  'tranexamicAcid',
  'tretinoin',
  'urea',
  'vitaminC',
  'vitaminE',
  'zincOxide',
  'zincPyrithione',
] as const;

export const INGREDIENTS_FOR_HAIR = [
  'caffeine',
  'dutasteride',
  'finasteride',
  'ketoconazole',
  'melatonin',
  'minoxidil',
  'spironolactone',
] as const;

type TupleToUnion<T extends readonly string[]> = T[number];

// Sort the combined array of ingredients (remove duplicates)
const sortedIngredients = [
  ...new Set([...INGREDIENTS_FOR_SKIN, ...INGREDIENTS_FOR_HAIR]),
].sort();

// Create a read-only tuple from the sorted array
export const INGREDIENTS = sortedIngredients as readonly string[];

export type INGREDIENT = typeof INGREDIENTS[number];

interface IngredientDescriptions {
  short: string;
  medium: string;
  long: string;
}

export interface IngredientInfo {
  description: IngredientDescriptions;
  icon: JSX.Element | undefined;
  displayName: string;
  guideUrl: string | undefined;
}

interface GetIngredientInfoOptions {
  hasAntiAgingPrimaryDiagnosis?: boolean;
  treatmentCategory?: TreatmentCategory;
}

const VITAMIN_C_DISPLAY_NAME = 'Ascorbic acid (vitamin C)';

const BENZOYL_PEROXIDE_DESCRIPTIONS = {
  short:
    'A topical antimicrobial that kills skin surface bacteria and can prevent excess oil production to help treat and fight acne',
  medium:
    'A topical antimicrobial that kills skin surface bacteria and can prevent excess oil production to help treat and fight acne',
  long: 'A topical antimicrobial that kills skin surface bacteria and can prevent excess oil production to help treat and fight acne',
};

const SALICYLIC_ACID_DESCRIPTIONS = {
  short: 'Goes deep into pores to clear away dead skin cells and excess oil',
  medium: 'A chemical exfoliant that clears dead skin cells, dirt, and oil',
  long: 'Also known as beta hydroxy acid, this chemical exfoliant clears dead skin cells, dirt, and oil.',
};

const SPIRONOLACTONE_DESCRIPTIONS = {
  short: 'Helps stop the cycle of stubborn hormonal acne',
  medium:
    'A medication that helps stop the cycle of stubborn hormonal acne by reducing the skin’s oil production',
  long: 'This topical medication helps stop the cycle of stubborn hormonal acne by reducing the skin’s oil production.',
};

const SULFACETAMIDE_DESCRIPTIONS = {
  short: 'Helps to fight acne-contributing bacteria on the skin',
  medium: 'An antibiotic that fights acne-contributing bacteria on the skin',
  long: 'Also known as sulfacetamide sodium, this antibiotic is used to treat acne, dandruff, and other skin conditions.',
};

const ZINC_PYRITHIONE_DESCRIPTIONS = {
  short: 'Soothes inflammation and helps keep bacteria and fungi in check',
  medium:
    'An antimicrobial that helps keep bacteria and fungi in check while soothing inflammation',
  long: 'This antimicrobial helps keep bacteria and fungi in check—targeting potential contributors to acne—while also soothing inflammation.',
};

/**
 * Do not import this. It is exported only for use in testing.
 * @deprecated
 */
export const INGREDIENTS_DISPLAY_NAME_MAP_INTERNAL_USE_ONLY: Record<
  INGREDIENT,
  string
> = {
  adenosine: 'Adenosine',
  allantoin: 'Allantoin',
  aloe: 'Aloe',
  aloeVera: 'Aloe vera',
  arganOil: 'Argan oil',
  ascorbicAcid: VITAMIN_C_DISPLAY_NAME,
  avobenzone: 'Avobenzone',
  azelaicAcid: 'Azelaic acid',
  benzoylPeroxide: 'Benzoyl peroxide 2.5%',
  betaCarotene: 'Beta-carotene',
  caffeine: 'Caffeine',
  carnaubaWax: 'Carnauba wax',
  ceramides: 'Ceramides',
  clindamycinPhosphate: 'Clindamycin phosphate',
  desonide: 'Desonide',
  dexpanthenol: 'Dexpanthenol',
  dimethicone: 'Dimethicone',
  dutasteride: 'Dutasteride',
  ectoin: 'Ectoin',
  finasteride: 'Finasteride',
  glycerin: 'Glycerin',
  glycolicAcid: 'Glycolic acid (AHA)',
  greenTeaExtractEpigallocatechinGallate:
    'Green tea extract (epigallocatechin gallate)',
  hyaluronicAcid: 'Hyaluronic acid',
  hyaluronicAcidPolyglutamicAcidComplex:
    'Hyaluronic Acid-5 + Polyglutamic Acid Complex',
  hydrocolloidMatrix: 'Hydrocolloid matrix',
  hydrocortisone: 'Hydrocortisone',
  hydroquinone: 'Hydroquinone',
  ivermectin: 'Ivermectin',
  jojobaSeedOil: 'Jojoba seed oil',
  ketoconazole: 'Ketoconazole',
  kojicAcid: 'Kojic acid',
  licoriceRootExtract: 'Licorice root extract',
  linoleicAcid: 'Linoleic acid',
  melatonin: 'Melatonin',
  metronidazole: 'Metronidazole',
  minoxidil: 'Minoxidil',
  niacinamide: 'Niacinamide',
  octisalate: 'Octisalate',
  panthenol: 'Panthenol',
  peptides: 'Peptides',
  petrolatum: 'Petrolatum',
  polyglutamicAcid: 'Polyglutamic acid',
  resveratrol: 'Resveratrol',
  salicylicAcid: 'Salicylic acid',
  sheaButter: 'Shea butter',
  sodiumHyaluronate: 'Sodium hyaluronate',
  sodiumPca: 'Sodium PCA',
  spironolactone: 'Spironolactone',
  squalane: 'Squalane',
  sulfacetamideSodium: 'Sulfacetamide sodium',
  titaniumDioxide: 'Titanium dioxide',
  tranexamicAcid: 'Tranexamic acid',
  tretinoin: 'Tretinoin',
  urea: 'Urea',
  vitaminC: VITAMIN_C_DISPLAY_NAME,
  vitaminE: 'Vitamin E',
  zincOxide: 'Zinc oxide',
  zincPyrithione: 'Zinc pyrithione',
};

// We do not have radiance icons for all ingredients.
/**
 * Do not import this. It is exported only for use in testing.
 * @deprecated
 */
export const INGREDIENTS_ICON_MAP_INTERNAL_USE_ONLY: {
  [ingredient in INGREDIENT]?: {
    skin: JSX.Element | undefined;
    hair: JSX.Element | undefined;
  };
} = {
  ascorbicAcid: {
    skin: <VitaminCGlyph />,
    hair: undefined,
  },
  azelaicAcid: {
    skin: <AzelaicAcidGlyph />,
    hair: undefined,
  },
  caffeine: {
    skin: <CaffeineGlyph />,
    hair: (
      <svg
        data-ingredient="squeeze-icon"
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M30.8669 14.0154C31.5696 15.2451 31.5696 16.7548 30.8669 17.9845L25.1526 27.9846C24.4404 29.2309 23.115 30 21.6796 30H10.3222C8.88677 30 7.56139 29.2309 6.84922 27.9846L1.13494 17.9845C0.432234 16.7548 0.432235 15.2451 1.13494 14.0154L6.84922 4.01544C7.56139 2.76914 8.88677 2 10.3222 2L21.6796 2C23.115 2 24.4404 2.76914 25.1526 4.01544L30.8669 14.0154Z"
          fill="#1D1D44"
        />
        <path
          d="M19.0616 20.1699C18.6749 20.1699 18.3272 20.0938 18.0186 19.9414C17.7139 19.7891 17.4737 19.5723 17.2979 19.291C17.126 19.0059 17.0401 18.6738 17.0401 18.2949C17.0401 17.7168 17.21 17.2754 17.5499 16.9707C17.8897 16.6621 18.3799 16.4609 19.0206 16.3672L20.0928 16.2148C20.4092 16.168 20.6338 16.1016 20.7667 16.0156C20.8995 15.9258 20.9659 15.7871 20.9659 15.5996C20.9659 15.4004 20.8877 15.2324 20.7315 15.0957C20.5752 14.959 20.3545 14.8906 20.0694 14.8906C19.7647 14.8906 19.5206 14.9707 19.337 15.1309C19.1534 15.2871 19.0538 15.502 19.0381 15.7754H17.2042C17.2198 15.3613 17.3448 14.9941 17.5792 14.6738C17.8174 14.3496 18.1475 14.0977 18.5694 13.918C18.9913 13.7383 19.4795 13.6484 20.0342 13.6484C20.6163 13.6484 21.1084 13.7441 21.5108 13.9355C21.9131 14.123 22.2159 14.3926 22.419 14.7441C22.626 15.0957 22.7295 15.5156 22.7295 16.0039V18.582C22.7295 18.8594 22.7491 19.1172 22.7881 19.3555C22.8311 19.5898 22.8917 19.7383 22.9698 19.8008V20H21.1241C21.0616 19.7422 21.0206 19.4824 21.001 19.2207C20.8096 19.5059 20.5479 19.7363 20.2159 19.9121C19.8838 20.084 19.4991 20.1699 19.0616 20.1699ZM18.8624 18.1543C18.8624 18.373 18.9424 18.5469 19.1026 18.6758C19.2627 18.8047 19.4678 18.8691 19.7178 18.8691C19.9678 18.8691 20.1885 18.8145 20.3799 18.7051C20.5713 18.5918 20.7178 18.4355 20.8194 18.2363C20.9249 18.0371 20.9776 17.8105 20.9776 17.5566V16.9824C20.8917 17.0566 20.7881 17.1172 20.667 17.1641C20.5499 17.2109 20.3995 17.252 20.2159 17.2871L19.7647 17.375C19.4639 17.4336 19.2374 17.5234 19.085 17.6445C18.9366 17.7656 18.8624 17.9355 18.8624 18.1543Z"
          fill="white"
        />
        <path
          d="M12.9207 20.1699C12.1316 20.1699 11.448 20 10.8699 19.6602C10.2957 19.3164 9.85235 18.8203 9.53985 18.1719C9.23125 17.5195 9.07696 16.7402 9.07696 15.834C9.07696 14.9199 9.23321 14.1328 9.54571 13.4727C9.85821 12.8125 10.3035 12.3086 10.8816 11.9609C11.4637 11.6094 12.1473 11.4336 12.9324 11.4336C13.6238 11.4336 14.2332 11.5605 14.7605 11.8145C15.2918 12.0645 15.7098 12.4316 16.0145 12.916C16.3191 13.4004 16.4832 13.9844 16.5066 14.668H14.6434C14.5965 14.1172 14.4246 13.6973 14.1277 13.4082C13.8348 13.1152 13.4422 12.9688 12.95 12.9688C12.5359 12.9688 12.1805 13.0781 11.8836 13.2969C11.5906 13.5156 11.366 13.8359 11.2098 14.2578C11.0535 14.6797 10.9754 15.1934 10.9754 15.7988C10.9754 16.7051 11.1492 17.4023 11.4969 17.8906C11.8445 18.375 12.3445 18.6172 12.9969 18.6172C13.4695 18.6172 13.8465 18.4648 14.1277 18.1602C14.4129 17.8516 14.577 17.4336 14.6199 16.9063H16.5008C16.4617 17.6211 16.2879 18.2227 15.9793 18.7109C15.6746 19.1992 15.2605 19.5645 14.7371 19.8066C14.2137 20.0488 13.6082 20.1699 12.9207 20.1699Z"
          fill="white"
        />
      </svg>
    ),
  },
  clindamycinPhosphate: {
    skin: <ClindamycinGlyph />,
    hair: undefined,
  },
  desonide: {
    skin: <DesonideGlyph />,
    hair: undefined,
  },
  dexpanthenol: {
    skin: <DexpanthenolGlyph />,
    hair: undefined,
  },
  finasteride: {
    skin: undefined,
    hair: (
      <svg
        data-ingredient="squeeze-icon"
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M30.8669 17.9845C31.5696 16.7548 31.5696 15.2451 30.8669 14.0154L25.1526 4.01544C24.4404 2.76914 23.115 2 21.6796 2L10.3222 2C8.88677 2 7.5614 2.76914 6.84922 4.01544L1.13494 14.0154C0.432235 15.2451 0.432233 16.7548 1.13493 17.9845L6.84922 27.9846C7.56139 29.2309 8.88677 30 10.3222 30H21.6796C23.115 30 24.4404 29.2309 25.1526 27.9846L30.8669 17.9845ZM19.0468 11.5977H13.4452L13.4452 20H15.2851V16.6602H18.6718V15.1309H15.2851V13.1445H19.0468V11.5977Z"
          fill="#1D1D44"
        />
      </svg>
    ),
  },
  glycerin: {
    skin: <GlycerinGlyph />,
    hair: undefined,
  },
  greenTeaExtractEpigallocatechinGallate: {
    skin: <GreenTeaGlyph />,
    hair: undefined,
  },
  hydrocortisone: {
    skin: <HydrocortisoneGlyph />,
    hair: undefined,
  },
  hydroquinone: {
    skin: <HydroquinoneGlyph />,
    hair: undefined,
  },
  ivermectin: {
    skin: <IvermectinGlyph />,
    hair: undefined,
  },
  ketoconazole: {
    skin: <KetoconazoleGlyph />,
    hair: (
      <svg
        data-ingredient="squeeze-icon"
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M30.8669 17.9845C31.5696 16.7548 31.5696 15.2451 30.8669 14.0154L25.1526 4.01544C24.4404 2.76914 23.115 2 21.6796 2L10.3222 2C8.88677 2 7.5614 2.76914 6.84922 4.01544L1.13494 14.0154C0.432235 15.2451 0.432233 16.7548 1.13493 17.9845L6.84922 27.9846C7.56139 29.2309 8.88677 30 10.3222 30H21.6796C23.115 30 24.4404 29.2309 25.1526 27.9846L30.8669 17.9845ZM14.7109 11.5977H12.8769L12.8769 20H14.7109V17.7441L15.8124 16.4316L17.8456 20H19.9784L17.0195 14.9961L19.873 11.5977H17.6581L14.7109 15.2832V11.5977Z"
          fill="#1D1D44"
        />
      </svg>
    ),
  },
  kojicAcid: {
    skin: <KojicAcidGlyph />,
    hair: undefined,
  },
  melatonin: {
    skin: undefined,
    hair: (
      <svg
        data-ingredient="squeeze-icon"
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M22.9357 16.1621C22.9045 15.7598 22.7873 15.4551 22.5842 15.248C22.3849 15.0371 22.1115 14.9316 21.7638 14.9316C21.424 14.9316 21.1408 15.0391 20.9142 15.2539C20.6916 15.4648 20.549 15.7676 20.4865 16.1621L22.9357 16.1621Z"
          fill="#1D1D44"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M30.8669 14.0154C31.5696 15.2451 31.5696 16.7548 30.8669 17.9845L25.1526 27.9846C24.4404 29.2309 23.115 30 21.6796 30H10.3222C8.88677 30 7.56139 29.2309 6.84922 27.9846L1.13494 17.9845C0.432234 16.7548 0.432235 15.2451 1.13494 14.0154L6.84922 4.01544C7.56139 2.76914 8.88677 2 10.3222 2L21.6796 2C23.115 2 24.4404 2.76914 25.1526 4.01544L30.8669 14.0154ZM21.7931 20.1699C21.1681 20.1699 20.6252 20.0508 20.1642 19.8125C19.7033 19.5703 19.3439 19.207 19.0861 18.7227C18.8322 18.2383 18.7053 17.6387 18.7053 16.9238C18.7053 16.2441 18.8381 15.6582 19.1037 15.166C19.3693 14.6699 19.7365 14.293 20.2053 14.0352C20.674 13.7773 21.2072 13.6484 21.8049 13.6484C22.3791 13.6484 22.8888 13.7734 23.3342 14.0234C23.7795 14.2734 24.1252 14.6367 24.3713 15.1133C24.6213 15.5898 24.7463 16.1602 24.7463 16.8242C24.7463 17.0625 24.7443 17.252 24.7404 17.3926L20.4631 17.3926C20.4904 17.8691 20.6232 18.2344 20.8615 18.4883C21.0998 18.7383 21.4162 18.8633 21.8107 18.8633C22.1154 18.8633 22.3674 18.7852 22.5666 18.6289C22.7658 18.4688 22.8908 18.2441 22.9416 17.9551H24.717C24.6623 18.4082 24.508 18.8008 24.2541 19.1328C24.0041 19.4648 23.6701 19.7207 23.2521 19.9004C22.8342 20.0801 22.3478 20.1699 21.7931 20.1699ZM11.4804 11.5977H8.83196V20L10.5195 20V13.6016L12.3769 20L14.1464 20L16.0156 13.5957V20H17.7031V11.5977H15.0546L13.2734 17.7559L11.4804 11.5977Z"
          fill="#1D1D44"
        />
      </svg>
    ),
  },
  metronidazole: {
    skin: <MetronidazoleGlyph />,
    hair: undefined,
  },
  minoxidil: {
    skin: undefined,
    hair: (
      <svg
        data-ingredient="squeeze-icon"
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M30.8669 14.0154C31.5696 15.2451 31.5696 16.7548 30.8669 17.9845L25.1526 27.9846C24.4404 29.2309 23.115 30 21.6796 30H10.3222C8.88677 30 7.56139 29.2309 6.84922 27.9846L1.13493 17.9845C0.432233 16.7548 0.432235 15.2451 1.13494 14.0154L6.84922 4.01544C7.5614 2.76914 8.88677 2 10.3222 2L21.6796 2C23.115 2 24.4404 2.76914 25.1526 4.01544L30.8669 14.0154ZM20.2569 13.8184L22.0323 13.8184V20H20.2569V13.8184ZM21.1534 13.373C20.9581 13.373 20.7803 13.3301 20.6202 13.2441C20.46 13.1543 20.335 13.0332 20.2452 12.8809C20.1553 12.7285 20.1104 12.5605 20.1104 12.377C20.1104 12.1934 20.1553 12.0254 20.2452 11.873C20.335 11.7207 20.46 11.6016 20.6202 11.5156C20.7803 11.4258 20.9581 11.3809 21.1534 11.3809C21.3448 11.3809 21.5186 11.4258 21.6749 11.5156C21.8311 11.6016 21.9542 11.7207 22.044 11.873C22.1377 12.0254 22.1846 12.1934 22.1846 12.377C22.1846 12.5605 22.1377 12.7285 22.044 12.8809C21.9542 13.0332 21.8311 13.1543 21.6749 13.2441C21.5186 13.3301 21.3448 13.373 21.1534 13.373ZM12.7274 11.5977H10.0789L10.0789 20H11.7664L11.7664 13.6016L13.6238 20H15.3934L17.2625 13.5957V20H18.95V11.5977H16.3016L14.5203 17.7559L12.7274 11.5977Z"
          fill="#1D1D44"
        />
      </svg>
    ),
  },
  niacinamide: {
    skin: <NiacinamideGlyph />,
    hair: undefined,
  },
  resveratrol: {
    skin: <ResveratrolGlyph />,
    hair: undefined,
  },
  salicylicAcid: {
    skin: <CloggedPoresGlyph />,
    hair: undefined,
  },
  spironolactone: {
    skin: <SpironolactoneGlyph />,
    hair: (
      <svg
        data-ingredient="squeeze-icon"
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M30.8669 17.9845C31.5696 16.7548 31.5696 15.2451 30.8669 14.0154L25.1526 4.01544C24.4404 2.76914 23.115 2 21.6796 2L10.3222 2C8.88677 2 7.5614 2.76914 6.84922 4.01544L1.13494 14.0154C0.432235 15.2451 0.432233 16.7548 1.13493 17.9845L6.84922 27.9846C7.56139 29.2309 8.88677 30 10.3222 30H21.6796C23.115 30 24.4404 29.2309 25.1526 27.9846L30.8669 17.9845ZM14.33 19.8418C14.8417 20.0605 15.4355 20.1699 16.1113 20.1699C16.7636 20.1699 17.3281 20.0625 17.8046 19.8477C18.2851 19.6289 18.6523 19.3164 18.9062 18.9102C19.164 18.5 19.2929 18.0137 19.2929 17.4512C19.2929 16.7715 19.0956 16.2461 18.7011 15.875C18.3066 15.5 17.7597 15.2363 17.0605 15.084L15.8183 14.8145C15.4316 14.7324 15.1523 14.6152 14.9804 14.4629C14.8085 14.3105 14.7226 14.1133 14.7226 13.8711C14.7226 13.6758 14.7773 13.502 14.8866 13.3496C14.996 13.1973 15.1464 13.0801 15.3378 12.998C15.5292 12.916 15.7441 12.875 15.9824 12.875C16.3886 12.875 16.705 12.9766 16.9316 13.1797C17.1581 13.3789 17.2773 13.6426 17.289 13.9707H19.1581C19.1347 13.4863 18.996 13.0527 18.7421 12.6699C18.4921 12.2871 18.1366 11.9863 17.6757 11.7676C17.2148 11.5449 16.6679 11.4336 16.0351 11.4336C15.4023 11.4336 14.8437 11.5449 14.3593 11.7676C13.8788 11.9902 13.5058 12.2988 13.2402 12.6934C12.9745 13.084 12.8417 13.5293 12.8417 14.0293C12.8417 14.7051 13.0331 15.2363 13.4159 15.623C13.7988 16.0098 14.4023 16.293 15.2265 16.4727L16.3925 16.7305C16.7636 16.8125 17.0273 16.9277 17.1835 17.0762C17.3398 17.2246 17.4179 17.4277 17.4179 17.6855C17.4179 18.0059 17.3007 18.2559 17.0663 18.4355C16.8359 18.6152 16.5273 18.7051 16.1406 18.7051C15.6757 18.7051 15.3046 18.584 15.0273 18.3418C14.7538 18.0996 14.6074 17.7734 14.5878 17.3633H12.7011C12.7089 17.9531 12.8554 18.459 13.1406 18.8809C13.4257 19.3027 13.8222 19.623 14.33 19.8418Z"
          fill="#1D1D44"
        />
      </svg>
    ),
  },
  sulfacetamideSodium: {
    skin: <CleanseGlyph />,
    hair: undefined,
  },
  tranexamicAcid: {
    skin: <TranexamicAcidGlyph />,
    hair: undefined,
  },
  tretinoin: {
    skin: <TretinoinGlyph />,
    hair: undefined,
  },
  vitaminC: {
    skin: <VitaminCGlyph />,
    hair: undefined,
  },
  zincPyrithione: {
    skin: <ZincGlyph />,
    hair: undefined,
  },
};

const VITAMIN_C_DESCRIPTION = {
  short: 'Protects and helps brighten skin while encouraging healing',
  medium:
    'A protective antioxidant that helps brighten skin, encourage healing, and boost collagen',
  long: 'This protective antioxidant helps brighten skin by slowing down melanin production and encourages healing by boosting collagen.',
};

/**
 * Do not import this. It is exported only for use in testing.
 * @deprecated
 */
export const INGREDIENTS_DESCRIPTION_ACNE_INTERNAL_USE_ONLY: Record<
  TupleToUnion<typeof INGREDIENTS_FOR_SKIN>,
  IngredientDescriptions
> = {
  adenosine: {
    short:
      'Helps support natural collagen production and visibly reduce the appearance of fine lines and wrinkles',
    medium:
      'An ingredient that helps support natural collagen production and visibly reduce the appearance of fine lines and wrinkles',
    long: 'An ingredient that helps support natural collagen production and visibly reduce the appearance of fine lines and wrinkles.',
  },
  allantoin: {
    short: 'Moisturizes, soothes, and protects skin while supporting healing',
    medium:
      'A multipurpose emollient that moisturizes, soothes, and protects skin while helping it heal',
    long: 'This multipurpose emollient moisturizes, soothes, and protects skin while helping it heal.',
  },
  aloe: {
    short: 'Hydrates and soothes skin',
    medium: 'A nutrient-rich plant extract that hydrates and soothes skin',
    long: 'This nutrient-rich plant extract hydrates and soothes skin.',
  },
  aloeVera: {
    short: 'Hydrates and soothes skin',
    medium: 'A nutrient-rich plant extract that hydrates and soothes skin',
    long: 'This nutrient-rich plant extract hydrates and soothes skin.',
  },
  arganOil: {
    short: 'Nourishes and softens skin with vitamin E and fatty acids',
    medium:
      'An oil that deeply nourishes and softens skin with vitamin E and fatty acids',
    long: 'An oil that deeply nourishes and softens skin with vitamin E and fatty acids.',
  },
  ascorbicAcid: VITAMIN_C_DESCRIPTION,
  avobenzone: {
    short: 'Protects against UVA rays with no white cast',
    medium:
      'A chemical sunscreen ingredient that protects against UVA rays without a white cast',
    long: 'This commonly used chemical sunscreen ingredient protects against UVA rays without leaving a white cast.',
  },
  azelaicAcid: {
    short:
      'Gently exfoliates to help unclog pores, smooth texture, and brighten skin',
    medium:
      'A multitasking exfoliant that helps unclog pores, smooth texture, and brighten skin',
    long: 'With antibacterial and anti-inflammatory properties, this multitasking exfoliant helps unclog pores, smooth texture, and brighten skin.',
  },
  benzoylPeroxide: BENZOYL_PEROXIDE_DESCRIPTIONS,
  betaCarotene: {
    short: 'Fights environmental stressors',
    medium: 'Fights environmental stressors',
    long: 'Fights environmental stressors',
  },
  caffeine: {
    short: 'Helps smooth skin and slow down photoaging effects',
    medium:
      'An antioxidant that can help smooth skin and slow down photoaging effects',
    long: 'An antioxidant that can help smooth skin and slow down photoaging effects',
  },
  carnaubaWax: {
    short:
      'Contributes to the lip balm’s silken, buttery texture and hydrated finish',
    medium:
      'A moisturizing wax that contributes to the lip balm’s silken, buttery texture and hydrated finish',
    long: 'This moisturizing wax contributes to the lip balm’s silken, buttery texture and hydrated finish.',
  },
  ceramides: {
    short: 'Fortify the moisture barrier to keep skin hydrated',
    medium:
      'Naturally occurring lipids that fortify the moisture barrier to keep skin hydrated',
    long: 'These naturally occurring lipids fortify the skin’s moisture barrier, increasing hydration and preventing water loss for plump skin.',
  },
  clindamycinPhosphate: {
    short: 'Fights acne-causing bacteria and inflammation',
    medium:
      'An antibiotic that fights acne-causing bacteria and soothes inflammation',
    long: 'Helping to reduce red and painful blemishes, this antibiotic fights acne-causing bacteria and soothes inflammation.',
  },
  // TODO-ZC: Await final copy from copywriting team
  desonide: {
    short: 'Reduces swelling, itching, and redness',
    medium: 'Reduces swelling, itching, and redness',
    long: 'Reduces swelling, itching, and redness',
  },
  dexpanthenol: {
    short: 'Attracts and holds moisture for softer, smoother skin',
    medium:
      'A vitamin B5 derivative that attracts and holds moisture for softer, smoother skin',
    long: 'This vitamin B5 derivative attracts and holds moisture for softer, smoother skin. Plus, it helps skin heal and soothes irritation.',
  },
  dimethicone: {
    short: 'Forms a barrier on the skin to minimize water loss',
    medium:
      'A silicone-based polymer that forms a temporary barrier on the skin to minimize water loss',
    long: 'Considered a skin protectant at certain levels, this silicone-based polymer forms a temporary barrier on the skin to minimize water loss and helps condition the skin.',
  },
  ectoin: {
    short:
      'A natural amino acid that helps reduce moisture loss, soothe skin, and support a healthy barrier',
    medium:
      'A natural amino acid that helps reduce moisture loss, soothe skin, and support a healthy barrier',
    long: 'A natural amino acid that helps reduce moisture loss, soothe skin, and support a healthy barrier',
  },
  finasteride: {
    short: '',
    medium: '',
    long: '',
  },
  glycerin: {
    short: 'Locks water in the skin’s outer layer to maximize moisture levels',
    medium:
      'A humectant that locks water in the skin’s outer layer to maximize moisture levels',
    long: 'By locking water in the skin’s outer layer, this humectant helps maximize moisture levels and slows the evaporation of water from your skin.',
  },
  glycolicAcid: {
    short: 'Promotes cell turnover to help reveal fresher, smoother skin',
    medium: 'Promotes cell turnover to help reveal fresher, smoother skin.',
    long: 'Promotes cell turnover to help reveal fresher, smoother skin.',
  },
  greenTeaExtractEpigallocatechinGallate: {
    short: 'Minimizes hyperpigmentation and fights damaging free radicals',
    medium:
      'An antioxidant that fights damaging free radicals and minimizes hyperpigmentation',
    long: 'Harvested from fresh tea leaves, this antioxidant fights damaging free radicals, minimizes hyperpigmentation, and increases moisture retention.',
  },
  hyaluronicAcid: {
    short: 'Attracts and holds water to deeply hydrate and plump the skin',
    medium:
      'A naturally occurring sugar that attracts water to deeply hydrate and plump the skin',
    long: 'Known for its ability to attract and hold water up to 1,000x its weight, hyaluronic acid deeply hydrates and plumps the skin, boosting its smoothness and elasticity.',
  },
  hyaluronicAcidPolyglutamicAcidComplex: {
    short:
      'Our Dual-Layered Hydration Technology that deeply hydrates multiple layers of skin and forms a protective layer to secure hydration levels',
    medium:
      'Our Dual-Layered Hydration Technology that deeply hydrates multiple layers of skin and forms a protective layer to secure hydration levels',
    long: 'Our Dual-Layered Hydration Technology that deeply hydrates multiple layers of skin and forms a protective layer to secure hydration levels',
  },
  hydrocolloidMatrix: {
    short: 'Absorbs pus and oil while promoting healing',
    medium:
      'A plant-based gel that absorbs pus and oil while promoting healing',
    long: 'Developed to protect wounds, this plant-based gel absorbs pus and oil while promoting healing.',
  },
  hydrocortisone: {
    short: 'Calms irritation like redness, itching, and swelling',
    medium:
      'A soothing topical steroid that calms irritation like redness, itching, and swelling',
    long: 'This soothing topical steroid calms irritation and inflammation—like redness, itching, and swelling—helping your skin adjust to other active ingredients.',
  },
  hydroquinone: {
    short: 'Blocks the production of excess pigment to help fade dark spots',
    medium:
      'A lightening agent that blocks the production of excess pigment to help fade dark spots',
    long: 'The gold-standard hyperpigmentation treatment, this lightening agent blocks the production of excess pigment to help fade dark spots and brighten the skin.',
  },
  ivermectin: {
    short:
      'Helps treat redness, inflammation, and acne-like bumps from rosacea',
    medium:
      'A topical antimicrobial that can help treat rosacea and reduce its symptoms, like redness, inflammation, and acne-like bumps',
    long: 'This topical antimicrobial can be a great option for those with rosacea, tackling a possible cause—skin mites—and helping relieve symptoms of redness, inflammation, and acne-like bumps.',
  },
  jojobaSeedOil: {
    short:
      'Deeply moisturizes lips without clogging pores or leaving a greasy after-feel',
    medium:
      'A nourishing, antioxidant-rich oil that deeply moisturizes lips without clogging pores or leaving a greasy after-feel',
    long: 'This nourishing, antioxidant-rich oil deeply moisturizes lips without clogging pores or leaving a greasy after-feel.',
  },
  ketoconazole: {
    short: 'Helps treat fungal acne and can help decrease oil production',
    medium:
      'An antimicrobial that helps treat fungal acne and can help decrease oil production',
    long: 'This antimicrobial helps combat a fungus that causes acne-like breakouts and prevents its growth while also potentially helping decrease sebum (oil) production.',
  },
  kojicAcid: {
    short: 'Brightens skin to help even out skin tone',
    medium:
      'A naturally derived antioxidant that brightens skin to help even out skin tone',
    long: 'Naturally derived from fungi, this antioxidant brightens skin and helps even out skin tone by blocking melanin production.',
  },
  licoriceRootExtract: {
    short:
      'Delivers antioxidant and anti-inflammatory effects to help fade dark spots',
    medium:
      'A plant extract with antioxidant properties that can help visibly fade dark spots, soothe skin, and visibly reduce redness',
    long: 'Derived from the licorice plant, it acts as a potent antioxidant with anti-inflammatory benefits. This extract also inhibits the production of an enzyme needed to produce melanin (pigment) in the skin. By doing so, it not only helps to prevent new dark spots from forming, but helps to fade existing spots.',
  },
  linoleicAcid: {
    short: 'Helps support the health of the skin barrier',
    medium: 'Helps support the health of the skin barrier',
    long: 'Helps support the health of the skin barrier',
  },
  metronidazole: {
    short: 'Can help soothe inflammation, including redness and swelling',
    medium:
      'An antibiotic that can help soothe inflammation, including redness and swelling',
    long: 'This antibiotic can help soothe inflammation, including swelling and redness seen in rosacea, while fighting acne-causing bacteria.',
  },
  niacinamide: {
    short: 'Soothes inflammation, fades dark spots, and fights free radicals',
    medium:
      'A form of vitamin B3 that soothes inflammation, fades dark spots, and fights free radicals',
    long: 'This multitasking form of vitamin B3 soothes inflammation, fades dark spots, fights free radicals, and improves moisture barrier function.',
  },
  octisalate: {
    short: 'Protects against UVB rays',
    medium:
      'A lightweight chemical sunscreen ingredient that protects against UVB rays—providing broad-spectrum protection when combined with avobenzone',
    long: 'This lightweight chemical sunscreen ingredient protects against UVB rays—providing broad-spectrum protection when combined with avobenzone.',
  },
  panthenol: {
    short:
      'A humectant that attracts and retains water while helping to maintain softness and elasticity for smoother, plumper-looking skin',
    medium:
      'A humectant that attracts and retains water while helping to maintain softness and elasticity for smoother, plumper-looking skin',
    long: 'A humectant that attracts and retains water while helping to maintain softness and elasticity for smoother, plumper-looking skin',
  },
  peptides: {
    short: 'Smooths the look of fine lines and wrinkles',
    medium:
      'Acids that helps to improve the look of fine lines and wrinkles by supporting natural collagen production',
    long: 'Acids that helps to improve the look of fine lines and wrinkles by supporting natural collagen production.',
  },
  petrolatum: {
    short: 'Seals skin with a temporary water-protective barrier',
    medium:
      'An emollient and occlusive that seals skin with a temporary water-protective barrier',
    long: 'A moisturizer and skin protectant, it forms a temporary physical barrier on the skin to help prevent moisture loss and provide relief for compromised skin',
  },
  polyglutamicAcid: {
    short: 'Hydrates skin to help reduce the look of fine lines and wrinkles',
    medium:
      'An acid that can help boost skin elasticity and help reduce the appearance of pigmentation and fine lines',
    long: 'An acid that can help boost skin elasticity and help reduce the appearance of pigmentation and fine lines.',
  },
  resveratrol: {
    short: 'Regulates pigment production and provides antioxidant protection',
    medium:
      'An antioxidant that regulates pigment production and helps prevent damage from UV rays and pollution',
    long: 'Derived from nutrient-rich foods like red grapes, this antioxidant regulates pigment production and helps prevent damage from UV rays and pollution.',
  },
  salicylicAcid: SALICYLIC_ACID_DESCRIPTIONS,
  sheaButter: {
    short: 'Seals in moisture to soften and smooth skin',
    medium:
      'A plant-based emollient that seals in moisture to soften and smooth skin',
    long: 'With a melting point at body temperature, this plant-based emollient seals in moisture to soften and smooth skin.',
  },
  sodiumHyaluronate: {
    short: 'Attracts and holds water to deeply hydrate and plump the skin',
    medium:
      'A naturally occurring ingredient that attracts water to deeply hydrate and plump the skin',
    long: 'Extracted from hyaluronic acid, it deeply hydrates and plumps the skin, boosting its smoothness and elasticity.',
  },
  sodiumPca: {
    short:
      'A humectant found naturally in skin that helps skin’s upper layers both draw in and retain moisture',
    medium:
      'A humectant found naturally in skin that helps skin’s upper layers both draw in and retain moisture',
    long: 'A humectant found naturally in skin that helps skin’s upper layers both draw in and retain moisture',
  },
  spironolactone: SPIRONOLACTONE_DESCRIPTIONS,
  squalane: {
    short: 'Locks in moisture to help soften skin',
    medium: 'A plant-based oil that locks in moisture to help soften skin',
    long: 'Mimicking skin’s natural sebum, this plant-based oil locks in moisture to help soften skin without feeling greasy.',
  },
  sulfacetamideSodium: SULFACETAMIDE_DESCRIPTIONS,
  titaniumDioxide: {
    short: 'Protects against both UVA and UVB rays',
    medium:
      'A mineral (aka physical) sunscreen ingredient that protects against both UVA and UVB rays',
    long: 'This mineral (aka physical) sunscreen ingredient provides broad-spectrum protection against both UVA and UVB rays.',
  },
  tranexamicAcid: {
    short: 'Helps lighten dark spots and reduce redness',
    medium:
      'A brightening amino acid derivative that helps lighten dark spots and reduce redness',
    long: 'Proven to address skin discoloration, this brightening amino acid derivative helps lighten dark spots and reduce redness.',
  },
  tretinoin: {
    short: 'Helps clear acne and smooth out texture and fine lines',
    medium:
      'A vitamin A derivative that helps clear acne and smooth out texture and fine lines by speeding up cell turnover',
    long: 'One of the most effective and well-researched retinoids, this vitamin A derivative helps clear acne and smooth texture and fine lines by speeding up cell turnover and boosting collagen production.',
  },
  urea: {
    short: 'Increases the water content in the top layers of skin',
    medium:
      'A humectant known for its unique combination of moisturizing and exfoliating benefits',
    long: 'A humectant known for its unique combination of moisturizing and exfoliating benefits.',
  },
  vitaminC: VITAMIN_C_DESCRIPTION,
  vitaminE: {
    short:
      'An antioxidant that helps to protect the lipid barrier of skin from free radical damage while moisturizing and soothing',
    medium:
      'An antioxidant that helps to protect the lipid barrier of skin from free radical damage while moisturizing and soothing',
    long: 'An antioxidant that helps to protect the lipid barrier of skin from free radical damage while moisturizing and soothing',
  },
  zincOxide: {
    short: 'Protects against both UVA and UVB rays',
    medium:
      'A mineral (aka physical) sunscreen ingredient that protects against both UVA and UVB rays with minimal risk of irritation',
    long: 'This mineral (aka physical) sunscreen ingredient provides broad-spectrum protection against both UVA and UVB rays with minimal risk of irritation.',
  },
  zincPyrithione: ZINC_PYRITHIONE_DESCRIPTIONS,
};

/**
 * Do not import this. It is exported only for use in testing.
 * @deprecated
 */
export const INGREDIENTS_DESCRIPTION_ANTI_AGING_INTERNAL_USE_ONLY: Record<
  TupleToUnion<typeof INGREDIENTS_FOR_SKIN>,
  IngredientDescriptions
> = {
  adenosine: {
    short:
      'Helps support natural collagen production and visibly reduce the appearance of fine lines and wrinkles',
    medium:
      'An ingredient that helps support natural collagen production and visibly reduce the appearance of fine lines and wrinkles',
    long: 'An ingredient that helps support natural collagen production and visibly reduce the appearance of fine lines and wrinkles.',
  },
  allantoin: {
    short: 'Moisturizes, soothes, and protects skin while supporting healing',
    medium:
      'A multipurpose emollient that moisturizes, soothes, and protects skin while helping it heal',
    long: 'This multipurpose emollient moisturizes, soothes, and protects skin while helping it heal.',
  },
  aloe: {
    short: 'Hydrates and soothes skin',
    medium: 'A nutrient-rich plant extract that hydrates and soothes skin',
    long: 'This nutrient-rich plant extract hydrates and soothes skin.',
  },
  aloeVera: {
    short: 'Hydrates and soothes skin',
    medium: 'A nutrient-rich plant extract that hydrates and soothes skin',
    long: 'This nutrient-rich plant extract hydrates and soothes skin.',
  },
  arganOil: {
    short: 'Nourishes and softens skin with vitamin E and fatty acids',
    medium:
      'An oil that deeply nourishes and softens skin with vitamin E and fatty acids',
    long: 'An oil that deeply nourishes and softens skin with vitamin E and fatty acids.',
  },
  ascorbicAcid: VITAMIN_C_DESCRIPTION,
  avobenzone: {
    short: 'Protects against UVA rays with no white cast',
    medium:
      'A chemical sunscreen ingredient that protects against UVA rays without a white cast',
    long: 'This commonly used chemical sunscreen ingredient protects against UVA rays without leaving a white cast.',
  },
  azelaicAcid: {
    short:
      'Gently exfoliates and reduces excess pigment to help smooth and brighten skin',
    medium:
      'A gentle exfoliant that clears away dull, dead skin cells and reduces excess pigment to help smooth texture and brighten skin',
    long: 'By gently clearing away dull, dead skin cells, this exfoliant reduces excess pigment and helps improve texture for brighter, smoother skin.',
  },
  benzoylPeroxide: BENZOYL_PEROXIDE_DESCRIPTIONS,
  betaCarotene: {
    short: 'Fights environmental stressors',
    medium: 'Fights environmental stressors',
    long: 'Fights environmental stressors',
  },
  caffeine: {
    short: 'Helps smooth skin and slow down photoaging effects',
    medium:
      'Antioxidant that can help smooth skin and slow down photoaging effects',
    long: 'Antioxidant that can help smooth skin and slow down photoaging effects',
  },
  carnaubaWax: {
    short:
      'Contributes to the lip balm’s silken, buttery texture and hydrated finish',
    medium:
      'A moisturizing wax that contributes to the lip balm’s silken, buttery texture and hydrated finish',
    long: 'This moisturizing wax contributes to the lip balm’s silken, buttery texture and hydrated finish.',
  },
  ceramides: {
    short: 'Fortify the moisture barrier to keep skin hydrated',
    medium:
      'Naturally occurring lipids that fortify the moisture barrier to keep skin hydrated',
    long: 'These naturally occurring lipids fortify the skin’s moisture barrier, increasing hydration and preventing water loss for plump skin.',
  },
  clindamycinPhosphate: {
    short: 'Fights acne-causing bacteria and inflammation',
    medium:
      'An antibiotic that fights acne-causing bacteria and soothes inflammation',
    long: 'Helping to reduce red and painful blemishes, this antibiotic fights acne-causing bacteria and soothes inflammation.',
  },
  // TODO-ZC: Await final copy from copywriting team
  desonide: {
    short: 'Reduces swelling, itching, and redness',
    medium: 'Reduces swelling, itching, and redness',
    long: 'Reduces swelling, itching, and redness',
  },
  dexpanthenol: {
    short: 'Attracts and holds moisture for softer, smoother skin',
    medium:
      'A vitamin B5 derivative that attracts and holds moisture for softer, smoother skin',
    long: 'This vitamin B5 derivative attracts and holds moisture for softer, smoother skin. Plus, it helps skin heal and soothes irritation.',
  },
  dimethicone: {
    short: 'Forms a barrier on the skin to minimize water loss',
    medium:
      'A silicone-based polymer that forms a temporary barrier on the skin to minimize water loss',
    long: 'Considered a skin protectant at certain levels, this silicone-based polymer forms a temporary barrier on the skin to minimize water loss and helps condition the skin.',
  },
  ectoin: {
    short:
      'A natural amino acid that helps reduce moisture loss, soothe skin, and support a healthy barrier',
    medium:
      'A natural amino acid that helps reduce moisture loss, soothe skin, and support a healthy barrier',
    long: 'A natural amino acid that helps reduce moisture loss, soothe skin, and support a healthy barrier',
  },
  finasteride: {
    short: '',
    medium: '',
    long: '',
  },
  glycerin: {
    short: 'Locks water in the skin’s outer layer to maximize moisture levels',
    medium:
      'A humectant that locks water in the skin’s outer layer to maximize moisture levels',
    long: 'By locking water in the skin’s outer layer, this humectant helps maximize moisture levels and slows the evaporation of water from your skin.',
  },
  glycolicAcid: {
    short: 'Promotes cell turnover to help reveal fresher, smoother skin',
    medium: 'Promotes cell turnover to help reveal fresher, smoother skin.',
    long: 'Promotes cell turnover to help reveal fresher, smoother skin.',
  },
  greenTeaExtractEpigallocatechinGallate: {
    short: 'Minimizes hyperpigmentation and fights damaging free radicals',
    medium:
      'An antioxidant that fights damaging free radicals and minimizes hyperpigmentation',
    long: 'Harvested from fresh tea leaves, this antioxidant fights damaging free radicals, minimizes hyperpigmentation, and increases moisture retention.',
  },
  hyaluronicAcid: {
    short: 'Attracts and holds water to deeply hydrate and plump the skin',
    medium:
      'A naturally occurring sugar that attracts water to deeply hydrate and plump the skin',
    long: 'Known for its ability to attract and hold water up to 1,000x its weight, hyaluronic acid deeply hydrates and plumps the skin, boosting its smoothness and elasticity.',
  },
  hyaluronicAcidPolyglutamicAcidComplex: {
    short:
      'Our Dual-Layered Hydration Technology that deeply hydrates multiple layers of skin and forms a protective layer to secure hydration levels',
    medium:
      'Our Dual-Layered Hydration Technology that deeply hydrates multiple layers of skin and forms a protective layer to secure hydration levels',
    long: 'Our Dual-Layered Hydration Technology that deeply hydrates multiple layers of skin and forms a protective layer to secure hydration levels',
  },
  hydrocolloidMatrix: {
    short: 'Absorbs pus and oil while promoting healing',
    medium:
      'A plant-based gel that absorbs pus and oil while promoting healing',
    long: 'Developed to protect wounds, this plant-based gel absorbs pus and oil while promoting healing.',
  },
  hydrocortisone: {
    short: 'Calms irritation like redness, itching, and swelling',
    medium:
      'A soothing topical steroid that calms irritation like redness, itching, and swelling',
    long: 'This soothing topical steroid calms irritation and inflammation—like redness, itching, and swelling—helping your skin adjust to other active ingredients.',
  },
  hydroquinone: {
    short: 'Blocks the production of excess pigment to help fade dark spots',
    medium:
      'A lightening agent that blocks the production of excess pigment to help fade dark spots',
    long: 'The gold-standard hyperpigmentation treatment, this lightening agent blocks the production of excess pigment to help fade dark spots and brighten the skin.',
  },
  ivermectin: {
    short:
      'Helps treat redness, inflammation, and acne-like bumps from rosacea',
    medium:
      'A topical antimicrobial that can help treat rosacea and reduce its symptoms, like redness, inflammation, and acne-like bumps',
    long: 'This topical antimicrobial can be a great option for those with rosacea, tackling a possible cause—skin mites—and helping relieve symptoms of redness, inflammation, and acne-like bumps.',
  },
  jojobaSeedOil: {
    short:
      'Deeply moisturizes lips without clogging pores or leaving a greasy after-feel',
    medium:
      'A nourishing, antioxidant-rich oil that deeply moisturizes lips without clogging pores or leaving a greasy after-feel',
    long: 'This nourishing, antioxidant-rich oil deeply moisturizes lips without clogging pores or leaving a greasy after-feel.',
  },
  ketoconazole: {
    short: 'Helps treat fungal acne and can help decrease oil production',
    medium:
      'An antimicrobial that helps treat fungal acne and can help decrease oil production',
    long: 'This antimicrobial helps combat a fungus that causes acne-like breakouts and prevents its growth while also potentially helping decrease sebum (oil) production.',
  },
  kojicAcid: {
    short: 'Brightens skin to help even out skin tone',
    medium:
      'A naturally derived antioxidant that brightens skin to help even out skin tone',
    long: 'Naturally derived from fungi, this antioxidant brightens skin and helps even out skin tone by blocking melanin production.',
  },
  licoriceRootExtract: {
    short:
      'Delivers antioxidant and anti-inflammatory effects to help fade dark spots',
    medium:
      'A potent antioxidant derived from the licorice plant that has anti-inflammatory benefits and helps to remove excess pigment in the skin',
    long: 'Derived from the licorice plant, it acts as a potent antioxidant with anti-inflammatory benefits. This extract also inhibits the production of an enzyme needed to produce melanin (pigment) in the skin. By doing so, it not only helps to prevent new dark spots from forming, but helps to fade existing spots.',
  },
  linoleicAcid: {
    short: 'Helps support the health of the skin barrier',
    medium: 'Helps support the health of the skin barrier',
    long: 'Helps support the health of the skin barrier',
  },
  metronidazole: {
    short: 'Can help soothe inflammation, including redness and swelling',
    medium:
      'An antibiotic that can help soothe inflammation, including redness and swelling',
    long: 'This antibiotic can help soothe inflammation, including swelling and redness seen in rosacea, while fighting acne-causing bacteria.',
  },
  niacinamide: {
    short:
      'Fights free radicals, soothes inflammation and visibly fades dark spots',
    medium:
      'A form of vitamin B3 that fights free radicals, visibly fades dark spots, and improves moisture barrier function',
    long: 'A multitasker, this form of vitamin B3 fights free radicals, fades dark spots, improves moisture barrier function, and reduces inflammation.',
  },
  octisalate: {
    short: 'Protects against UVB rays',
    medium:
      'A lightweight chemical sunscreen ingredient that protects against UVB rays—providing broad-spectrum protection when combined with avobenzone',
    long: 'This lightweight chemical sunscreen ingredient protects against UVB rays—providing broad-spectrum protection when combined with avobenzone.',
  },
  panthenol: {
    short:
      'A humectant that attracts and retains water while helping to maintain softness and elasticity for smoother, plumper-looking skin',
    medium:
      'A humectant that attracts and retains water while helping to maintain softness and elasticity for smoother, plumper-looking skin',
    long: 'A humectant that attracts and retains water while helping to maintain softness and elasticity for smoother, plumper-looking skin',
  },
  peptides: {
    short: 'Smooths the look of fine lines and wrinkles',
    medium:
      'Amino acids that helps to improve the look of fine lines and wrinkles by supporting natural collagen production',
    long: 'Amino acids that helps to improve the look of fine lines and wrinkles by supporting natural collagen production.',
  },
  petrolatum: {
    short: 'Forms a temporary barrier on the skin to minimize water loss',
    medium:
      'An emollient and occlusive that seals skin with a temporary water-protective barrier',
    long: 'A moisturizer and skin protectant, it forms a temporary physical barrier on the skin to help prevent moisture loss and provide relief for compromised skin.',
  },
  polyglutamicAcid: {
    short: 'Hydrates skin to help reduce the look of fine lines and wrinkles',
    medium:
      'An acid that can help boost skin elasticity and help reduce the appearance of pigmentation and fine lines',
    long: 'An acid that can help boost skin elasticity and help reduce the appearance of pigmentation and fine lines.',
  },
  resveratrol: {
    short: 'Regulates pigment production and provides antioxidant protection',
    medium:
      'An antioxidant that regulates pigment production and helps prevent damage from UV rays and pollution',
    long: 'Derived from nutrient-rich foods like red grapes, this antioxidant regulates pigment production and helps prevent damage from UV rays and pollution.',
  },
  salicylicAcid: SALICYLIC_ACID_DESCRIPTIONS,
  sheaButter: {
    short: 'Seals in moisture to soften and smooth skin',
    medium:
      'A plant-based emollient that seals in moisture to soften and smooth skin',
    long: 'With a melting point at body temperature, this plant-based emollient seals in moisture to soften and smooth skin.',
  },
  sodiumHyaluronate: {
    short: 'Attracts and holds water to deeply hydrate and plump the skin',
    medium:
      'A naturally occurring ingredient that attracts water to deeply hydrate and plump the skin',
    long: 'Extracted from hyaluronic acid, it deeply hydrates and plumps the skin, boosting its smoothness and elasticity.',
  },
  sodiumPca: {
    short:
      'A humectant found naturally in skin that helps skin’s upper layers both draw in and retain moisture',
    medium:
      'A humectant found naturally in skin that helps skin’s upper layers both draw in and retain moisture',
    long: 'A humectant found naturally in skin that helps skin’s upper layers both draw in and retain moisture',
  },
  spironolactone: SPIRONOLACTONE_DESCRIPTIONS,
  squalane: {
    short: 'Helps remove impurities and makeup while nourishing the skin',
    medium:
      'A plant-based oil that helps remove impurities and makeup while nourishing the skin',
    long: 'Mimicking skin’s natural sebum, this plant-based oil helps remove impurities and makeup while nourishing skin for a visibly smooth finish.',
  },
  sulfacetamideSodium: SULFACETAMIDE_DESCRIPTIONS,
  titaniumDioxide: {
    short: 'Protects against both UVA and UVB rays',
    medium:
      'A mineral (aka physical) sunscreen ingredient that protects against both UVA and UVB rays',
    long: 'This mineral (aka physical) sunscreen ingredient provides broad-spectrum protection against both UVA and UVB rays.',
  },
  tranexamicAcid: {
    short: 'Helps lighten dark spots and reduce redness',
    medium:
      'A brightening amino acid derivative that helps lighten dark spots and reduce redness',
    long: 'Proven to address skin discoloration, this brightening amino acid derivative helps lighten dark spots and reduce redness.',
  },
  tretinoin: {
    short: 'Helps smooth texture and fine lines while brightening skin',
    medium:
      'A vitamin A derivative that helps smooth texture and fine lines while brightening skin by speeding up cell turnover and boosting collagen production',
    long: 'As the gold-standard anti-aging ingredient, this vitamin A derivative helps smooth texture and fine lines while brightening skin by speeding up cell turnover and boosting collagen production.',
  },
  urea: {
    short: 'Increases the water content in the top layers of skin',
    medium:
      'A humectant known for its unique combination of moisturizing and exfoliating benefits',
    long: 'A humectant known for its unique combination of moisturizing and exfoliating benefits.',
  },
  vitaminC: VITAMIN_C_DESCRIPTION,
  vitaminE: {
    short:
      'An antioxidant that helps to protect the lipid barrier of skin from free radical damage while moisturizing and soothing',
    medium:
      'An antioxidant that helps to protect the lipid barrier of skin from free radical damage while moisturizing and soothing',
    long: 'An antioxidant that helps to protect the lipid barrier of skin from free radical damage while moisturizing and soothing',
  },
  zincOxide: {
    short: 'Protects against both UVA and UVB rays',
    medium:
      'A mineral (aka physical) sunscreen ingredient that protects against both UVA and UVB rays with minimal risk of irritation',
    long: 'This mineral (aka physical) sunscreen ingredient provides broad-spectrum protection against both UVA and UVB rays with minimal risk of irritation.',
  },
  zincPyrithione: ZINC_PYRITHIONE_DESCRIPTIONS,
};

export const INGREDIENTS_DESCRIPTION_HAIR_INTERNAL_USE_ONLY: Record<
  TupleToUnion<typeof INGREDIENTS_FOR_HAIR>,
  IngredientDescriptions
> = {
  finasteride: {
    short: 'Supports hair growth and helps prevent hair loss',
    medium:
      'Counteracts the effects of DHT (a hormone that plays a major role in hair loss), supports hair regrowth, and helps prevent hair loss',
    long: 'Counteracts the effects of DHT (a hormone that plays a major role in hair loss), while supporting hair regrowth, improving hair density, and helping to prevent hair loss.',
  },
  minoxidil: {
    short: 'Stimulates blood flow to hair and helps boost hair growth',
    medium:
      'Stimulates blood flow to hair follicles and extends the growth phase of hair—ultimately boosting hair growth',
    long: 'Stimulates blood flow to hair follicles and extends the growth phase of hair—promoting hair growth. It has been shown to increase hair density, improve scalp coverage, and enhance hair thickness.',
  },
  dutasteride: {
    short:
      'Prevents hair loss by interfering with DHT production, while promoting regrowth',
    medium:
      'Prevents hair loss by interfering with DHT production, while promoting regrowth',
    long: 'Helps to prevent hair loss by interfering with the production of DHT, a hormone that plays a major role in hair loss, while promoting regrowth',
  },
  ketoconazole: {
    short: 'Antifungal that treats dandruff and stimulates hair regrowth',
    medium:
      'An antifungal agent that treats dandruff, while also stimulating hair regrowth',
    long: 'An antifungal agent that treats dandruff, while also working to stimulate hair regrowth',
  },
  spironolactone: {
    short: 'Helps restore hair fullness and prevent future hair loss',
    medium:
      'Helps to protect hair from DHT (a hormone that plays a major role in hair loss), while restoring hair fullness and helping prevent progressive hair loss',
    long: 'Helps protect hair from the effects of dihydrotestosterone (DHT), a hormone that contributes to hair loss. It can also help increase hair counts to restore hair fullness and help prevent progressive hair loss.',
  },
  caffeine: {
    short: 'Stimulates the hair follicle and extends the growth phase of hair',
    medium:
      'Stimulates the hair follicles and extends the growth phase of hair',
    long: 'Stimulates the hair follicles and extends the growth phase of hair, promoting hair regrowth',
  },
  melatonin: {
    short: 'Helps counteract free radicals, leading to hair regrowth',
    medium:
      'Antioxidant that counteracts free radicals, which can impair hair follicle function and lead to hair loss',
    long: 'An antioxidant that helps counteract free radicals, which can damage hair follicles and contribute to hair loss. By combating these free radicals, melatonin supports hair growth.',
  },
};

/**
 * Retrieves the description of an ingredient based on the treatment category and anti-aging preference.
 *
 * This function looks up and returns the detailed descriptions (short, medium, long) of a given ingredient.
 * It takes into consideration the treatment category (skin or hair) and whether the user has an anti-aging primary diagnosis.
 *
 * @param {TupleToUnion<typeof INGREDIENTS>} formattedKeyName - The key name of the ingredient for which to retrieve the description. This should be one of the values defined in the INGREDIENTS array.
 * @param {GetIngredientInfoOptions} [options] - Optional settings to configure the function's behavior.
 * @param {boolean} [options.hasAntiAgingPrimaryDiagnosis=false] - A flag to indicate whether the user has an anti-aging primary diagnosis. If true, the function will return anti-aging descriptions where applicable.
 * @param {TreatmentCategory} [options.treatmentCategory=TREATMENT_CATEGORIES.skin] - The category of treatment for which the ingredient is used. This determines which set of descriptions to retrieve. Defaults to 'skin'.
 *
 * @returns {IngredientDescriptions} An object containing short, medium, and long descriptions of the ingredient.
 */
const getIngredientDescription = (
  formattedKeyName: INGREDIENT,
  options?: GetIngredientInfoOptions,
): IngredientDescriptions => {
  const {
    hasAntiAgingPrimaryDiagnosis = false,
    treatmentCategory = TREATMENT_CATEGORIES.skin,
  } = options || {};

  let ingredientDescriptionMap: IngredientDescriptions | undefined;

  switch (treatmentCategory) {
    case TREATMENT_CATEGORIES.skin:
      ingredientDescriptionMap = hasAntiAgingPrimaryDiagnosis
        ? INGREDIENTS_DESCRIPTION_ANTI_AGING_INTERNAL_USE_ONLY[
            formattedKeyName as TupleToUnion<typeof INGREDIENTS_FOR_SKIN>
          ]
        : INGREDIENTS_DESCRIPTION_ACNE_INTERNAL_USE_ONLY[
            formattedKeyName as TupleToUnion<typeof INGREDIENTS_FOR_SKIN>
          ];
      break;
    case TREATMENT_CATEGORIES.hair:
      ingredientDescriptionMap =
        INGREDIENTS_DESCRIPTION_HAIR_INTERNAL_USE_ONLY[
          formattedKeyName as TupleToUnion<typeof INGREDIENTS_FOR_HAIR>
        ];
      break;
    default:
      ingredientDescriptionMap =
        INGREDIENTS_DESCRIPTION_ACNE_INTERNAL_USE_ONLY[
          formattedKeyName as TupleToUnion<typeof INGREDIENTS_FOR_SKIN>
        ];
  }

  if (ingredientDescriptionMap === undefined) {
    return {
      short: 'Description not found',
      medium: 'Description not found',
      long: 'Description not found',
    };
  }

  return ingredientDescriptionMap;
};

/**
 * Do not import this. It is exported only for use in testing.
 * @deprecated
 */
export const INGREDIENTS_GUIDE_URL_AGENCY_INTERNAL_USE_ONLY: {
  [ingredient in INGREDIENT]?: string;
} = {
  azelaicAcid: 'https://support.withagency.com/article/198-ff-azelaic-acid',
  caffeine:
    'https://support.withagency.com/en_us/what-to-know-about-caffeine-for-the-skin-HJGSS56dp',
  dexpanthenol: 'https://support.withagency.com/article/195-ff-dexpanthenol',
  glycerin:
    'https://support.withagency.com/en_us/what-to-know-about-glycerin-HJ1FF5Tua',
  greenTeaExtractEpigallocatechinGallate:
    'https://support.withagency.com/article/206-green-tea-extract',
  hydrocortisone: 'https://support.withagency.com/article/192-hydrocortisone',
  hydroquinone: 'https://support.withagency.com/article/162-hydroquinone',
  kojicAcid: 'https://support.withagency.com/article/191-dsf-kojic-acid',
  niacinamide: 'https://support.withagency.com/article/197-ff-niacinamide',
  resveratrol: 'https://support.withagency.com/article/189-dsf-resveratrol',
  tranexamicAcid: 'https://support.withagency.com/article/196-ff-tranexamic',
  tretinoin: 'https://support.withagency.com/article/199-ff-tretinoin',
};

/**
 * Do not import this. It is exported only for use in testing.
 * @deprecated
 */
export const INGREDIENTS_GUIDE_URL_CUROLOGY_INTERNAL_USE_ONLY: {
  [ingredient in INGREDIENT]?: string;
} = {
  azelaicAcid: 'https://curology.com/guides/skin-101/azelaic-acid',
  clindamycinPhosphate: 'https://curology.com/guides/skin-101/clindamycin',
  metronidazole: 'https://curology.com/guides/skin-101/metronidazole',
  niacinamide: 'https://curology.com/guides/skin-101/niacinamide',
  tranexamicAcid: 'https://curology.com/guides/skin-101/tranexamic-acid',
  tretinoin: 'https://curology.com/guides/skin-101/tretinoin',
  zincPyrithione: 'https://curology.com/guides/skin-101/zinc-pyrithione',
};

/**
 * Do not import this. It is exported only for use in testing.
 * @deprecated
 */
export const INGREDIENTS_GUIDE_URL_FALLBACK_INTERNAL_USE_ONLY: Record<
  'curology' | 'agency',
  string
> = {
  agency: 'https://support.withagency.com',
  curology: 'https://support.curology.com/article/13-ingredients',
};

// INGREDIENTS GUIDES URL
const getIngredientGuideUrl = (formattedKeyName: INGREDIENT): string => {
  const guideUrlMap = getBrandedContent({
    agency: INGREDIENTS_GUIDE_URL_AGENCY_INTERNAL_USE_ONLY,
    curology: INGREDIENTS_GUIDE_URL_CUROLOGY_INTERNAL_USE_ONLY,
  });

  const defaultGuideUrl = getBrandedContent(
    INGREDIENTS_GUIDE_URL_FALLBACK_INTERNAL_USE_ONLY,
  );

  const guideUrl = guideUrlMap[formattedKeyName];

  return guideUrl ?? defaultGuideUrl;
};

function isValidIngredient(ingredient: string): ingredient is INGREDIENT {
  return INGREDIENTS.includes(ingredient as INGREDIENT);
}

export function determineTreatmentCategory(
  ingredient: INGREDIENT,
  treatmentCategory?: TreatmentCategory,
): TreatmentCategory | undefined {
  if (!treatmentCategory) {
    const icons = INGREDIENTS_ICON_MAP_INTERNAL_USE_ONLY[ingredient];
    if (icons) {
      const categories = Object.keys(icons);

      return categories.find(
        (category) => icons[category as keyof typeof icons],
      ) as TreatmentCategory | undefined;
    }
  }
  return treatmentCategory;
}

function handleInvalidIngredient(ingredient: INGREDIENT): IngredientInfo {
  const msg = `Unsupported ingredient: ${ingredient} passed to getIngredientInfo(). You must add all ingredients inside the getIngredientInfo module: does a new ingredient need to be added to getIngredientInfoByShortName?`;
  if (process.env.NODE_ENV !== 'production') {
    throw new Error(msg);
  } else {
    errorReporter.notify(msg, {
      metadata: {
        validIngredient: isValidIngredient(ingredient),
        INGREDIENTS_DISPLAY_NAME_MAP_INTERNAL_USE_ONLY,
        INGREDIENTS,
      },
    });

    return {
      description: { short: '', medium: '', long: '' },
      displayName: ingredient,
      icon: undefined,
      guideUrl: undefined,
    };
  }
}

export default function getIngredientInfo(
  ingredient: INGREDIENT,
  options: GetIngredientInfoOptions = {},
): IngredientInfo {
  const { hasAntiAgingPrimaryDiagnosis } = options;
  const treatmentCategory = determineTreatmentCategory(
    ingredient,
    options.treatmentCategory,
  );

  const icon = treatmentCategory
    ? INGREDIENTS_ICON_MAP_INTERNAL_USE_ONLY[ingredient]?.[treatmentCategory]
    : undefined;
  const displayName =
    INGREDIENTS_DISPLAY_NAME_MAP_INTERNAL_USE_ONLY[ingredient];

  if (!isValidIngredient(ingredient) || !displayName) {
    return handleInvalidIngredient(ingredient);
  }

  const description = getIngredientDescription(ingredient, {
    hasAntiAgingPrimaryDiagnosis,
    treatmentCategory,
  });

  return {
    description,
    icon,
    displayName,
    guideUrl: getIngredientGuideUrl(ingredient),
  };
}

/**
 * React hook to compute (and memoize) the ingredient info for a given `ingredient`.
 *
 * Example use:
 * `const { displayName, description, icon } = useIngredientInfo('aloe', { hasAntiAgingPrimaryDiagnosis: true });`
 */
export function useIngredientInfo(
  ingredient: INGREDIENT,
  options: GetIngredientInfoOptions = {},
): IngredientInfo {
  const { hasAntiAgingPrimaryDiagnosis, treatmentCategory } = options;

  const computedInfo = useMemo(
    () =>
      getIngredientInfo(ingredient, {
        hasAntiAgingPrimaryDiagnosis,
        treatmentCategory,
      }),
    [ingredient, hasAntiAgingPrimaryDiagnosis, treatmentCategory],
  );

  return computedInfo;
}

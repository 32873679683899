import React from 'react';
import { observer } from 'mobx-react';
import { Alert } from 'radiance-ui';

import { useAlertStore } from 'jsx/stores/alertStore';

const AlertList: React.FC = () => {
  const { alertStore } = useAlertStore();

  return (
    <div aria-live="assertive" aria-atomic="true" aria-relevant="additions">
      <Alert.Container>
        {alertStore.alerts.map((alert) => (
          <Alert
            key={alert.id}
            content={alert.content}
            avatarSrc={alert.avatarSrc === null ? undefined : alert.avatarSrc}
            ctaContent={alert.ctaContent}
            type={alert.type === 'info' ? 'default' : alert.type}
            duration={alert.duration}
            data-test-id={`alert-${alert.type}`}
            onExit={() => alertStore.removeAlert(alert.id)}
            truncateText={!!alert.truncateText}
          />
        ))}
      </Alert.Container>
    </div>
  );
};

export default observer(AlertList);

import { v4 } from 'uuid';

import PatientModel from '../models/patient';
import cookie from './cookie';
import COOKIE_KEYS from '../constants/cookieKeys';
import CONFIG from '../constants/config';

export const getLdUuidAndSetLdUuidCookie = (patient?: PatientModel | null) => {
  const ldUuid =
    patient?.launchDarklyUuid ??
    cookie.get(COOKIE_KEYS.ldUuid) ??
    cookie.get(COOKIE_KEYS.curologyUuid) ??
    v4();

  if (ldUuid !== cookie.get(COOKIE_KEYS.ldUuid)) {
    cookie.set(COOKIE_KEYS.ldUuid, ldUuid, {
      domain: CONFIG.gatsbyCookieDomain,
      expires: 395,
    });
  }

  return ldUuid;
};

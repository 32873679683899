import { Moment } from 'moment';

import { momentOrNull } from 'jsx/utils/dateUtils';

import { PrescriptionType } from '../types';
import FormulationModel from './formulationModel';

export default class PrescriptionModel {
  id: number;

  prescribedAt: Moment | null;

  formulation: FormulationModel;

  prescriptionPlanId: number;

  constructor(data: PrescriptionType) {
    this.id = data.id;
    this.prescribedAt = momentOrNull(data.prescribedAt);
    this.formulation = new FormulationModel(data.formulation);
    this.prescriptionPlanId = data.prescriptionPlanId;
  }
}

import { observable, action, makeObservable } from 'mobx';

import useStores from 'jsx/utils/hooks/useStores';

const DEFAULT_OPTIONS = {
  modalContent: null,
  footerContent: null,
  headerImage: null,
  title: '',
  onClose: () => undefined,
  showModal: false,
};

interface OptionsType {
  onClose?: () => void;
  footerContent?: JSX.Element;
  headerImage?: JSX.Element;
  title?: string;
}

class ImmersiveModalStore {
  @observable
  showModal = DEFAULT_OPTIONS.showModal;

  modalContent: JSX.Element | null = DEFAULT_OPTIONS.modalContent;

  @observable
  onClose: () => void = DEFAULT_OPTIONS.onClose;

  @observable
  footerContent: JSX.Element | null = DEFAULT_OPTIONS.footerContent;

  @observable
  headerImage: JSX.Element | null = DEFAULT_OPTIONS.headerImage;

  @observable
  title: string = DEFAULT_OPTIONS.title;

  constructor() {
    makeObservable(this);
  }

  @action
  openModal(content: JSX.Element, optionsParam: OptionsType = {}) {
    this.modalContent = content;
    this.onClose = optionsParam.onClose || DEFAULT_OPTIONS.onClose;
    this.footerContent =
      optionsParam.footerContent || DEFAULT_OPTIONS.footerContent;
    this.headerImage = optionsParam.headerImage || DEFAULT_OPTIONS.headerImage;
    this.title = optionsParam.title || DEFAULT_OPTIONS.title;
    this.showModal = true;
  }

  @action
  closeModal = () => {
    this.showModal = false;
    this.onClose();
    this.modalContent = DEFAULT_OPTIONS.modalContent;
    this.onClose = DEFAULT_OPTIONS.onClose;
    this.footerContent = DEFAULT_OPTIONS.footerContent;
    this.headerImage = DEFAULT_OPTIONS.headerImage;
    this.title = DEFAULT_OPTIONS.title;
  };
}

export const useImmersiveModalStore = (): {
  immersiveModalStore: ImmersiveModalStore;
} => {
  const { immersiveModalStore } = useStores();

  return { immersiveModalStore };
};

export default ImmersiveModalStore;

const FORMATS = {
  dateTime: {
    long: 'MMMM Do, YYYY h:mm A', // September 4th, 1990 8:30 PM
    longDay: 'ddd, MMM D, YYYY h:mm A', // Tue, Sep 4, 1990 8:30 PM
    local: 'YYYY-MM-DDTHH:mm', // 2018-06-12T19:31
    short: 'M/D/YYYY h:mm A', // 9/4/1990 8:30 PM
    yearMonthDayTime: 'Y-MM-DD, hh:mm:ss', // 2020-12-22 12:00:00
  },
  date: {
    dayOnly: 'Do', // 4th
    withoutYear: 'MMMM Do', // September 4th
    long: 'MMMM Do, YYYY', // September 4th, 1990
    short: 'MMM D, YYYY', // Sep 4, 1990
    standard: 'MMMM D, YYYY', // September 4, 1990
    numeric: 'M/D/YYYY', // 9/4/1990
    numericExtendedWithoutForwardSlash: 'MMDDYYYY', // 09041990
    numericShort: 'M/D/YY', // 9/4/90
    condensed: 'M/D', // 9/4
    yearMonthDay: 'Y-MM-DD', // 2020-06-01, mainly used for shipments
    dayShortMonthDate: 'dddd, MMM D', // Thursday, Dec 24
    dayLongMonthDate: 'dddd, MMMM D', // Thursday, December 24
  },
  time: {
    localWithTimeZone: 'h:mm A z',
  },
} as const;

export default FORMATS;

import React from 'react';
import { LoadingSpinner } from 'radiance-ui';

import { Container } from './style';

/** Loader to display before any content is rendered (for ensureInitialized,
 *  manual data fetching, Suspense fallback, etc. )
 * */
const Loading: React.FC = () => (
  <Container className="loadingContainer" data-test-id="loading">
    <LoadingSpinner bgColor="transparent" />
  </Container>
);

export default Loading;

import {
  ItemCategoryType,
  ItemTypeType,
  PRODUCT_CATEGORY,
} from 'jsx/constants/items';
import { ItemSize } from 'jsx/api/generated';

export interface BaseItemData {
  name: string;
  price: number;
  type: ItemTypeType;
  category: ItemCategoryType;
  size: ItemSize | null;
  trialVersionId: number | null;
  isPrescription: boolean;
  isTrialOnly: boolean;
}

export default class BaseItemModel {
  constructor(data: BaseItemData) {
    this.name = data.name;
    this.price = data.price;
    this.type = data.type;
    this.category = data.category;
    this.size = data.size;
    this.trialVersionId = data.trialVersionId;
    this.isPrescription = data.isPrescription;
    this.isTrialOnly = data.isTrialOnly;
  }

  readonly name: string;

  readonly price: number;

  readonly type: ItemTypeType;

  readonly category: ItemCategoryType;

  readonly size: ItemSize | null;

  readonly trialVersionId: number | null;

  readonly isPrescription: boolean;

  readonly isTrialOnly: boolean;

  get isProductCategory(): boolean {
    return this.category === PRODUCT_CATEGORY;
  }
}

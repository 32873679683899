import styled from '@emotion/styled';
import { GRID, SPACER } from 'radiance-ui/lib/constants';

export const ContentContainer = styled.section<{ size?: string }>`
  margin: 0 auto;
  position: relative;
  padding: 0 ${GRID.standardPadding};

  ${({ size }) => {
    switch (size) {
      case 'xs':
        return `max-width: ${GRID.xsContentMaxWidth};`;
      case 'sm':
        return `max-width: ${GRID.smContentMaxWidth};`;
      default:
        return `max-width: ${GRID.xlContentMaxWidth};`;
    }
  }};
`;

export const PageContainer = styled.section`
  min-height: 100vh;
  padding: ${SPACER.xlarge} ${GRID.standardPadding};
  width: 100%;
  position: relative;
`;

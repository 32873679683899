import route, { Params, Ziggy } from 'ziggy-js';

/*
 * We use Ziggy to use backend-named routes on the frontend:
 * https://github.com/tightenco/ziggy
 *
 * To expose a new route, add the route to config/ziggy.php
 */

const url = <T>(
  routeName: T,
  params: Params = {},
  absolute = false,
  customZiggy: Ziggy | null = null,
) => {
  return route(routeName, params, absolute, customZiggy)
    .url()
    .replace(/%3A/g, ':');
};

const enhancedPath =
  <T>(routeName: T) =>
  (params: Params = {}, absolute = false) =>
    url(routeName, params, absolute);

// Generics allow us to raise errors if we try to
// access a property that we did not pass to wrapUrls
// URLS.api.someNamedPath() --> No Error
// URLS.api.notActuallyNamed() --> Property does not exist on type
// Generic convention is usually single-characters, e.g. <T>, <K, V>,
// but we are using them with specific names to make this more readable
function mapRoutes<
  NamedRoutes extends valueof<ZiggyRoutes>,
  EnhancedPath,
  ZiggyRoutes,
>(
  enhancer: (routeName: NamedRoutes) => EnhancedPath,
  ziggyRoutes: ZiggyRoutes,
) {
  const wrappedRoutes = {} as {
    [routeKeys in keyof ZiggyRoutes]: EnhancedPath;
  };

  Object.entries(ziggyRoutes).forEach(([routeKey, routeName]) => {
    wrappedRoutes[routeKey as keyof ZiggyRoutes] = enhancer(routeName);
  });

  return wrappedRoutes;
}

export const wrapUrls = <T>(ziggyRoutes: T) =>
  mapRoutes(enhancedPath, ziggyRoutes);

export default url;

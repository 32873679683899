import { IngredientAllergiesAndSideEffectsType } from 'jsx/dashboard/views/treatmentPlan/ingredientContent';

import type { PrescriptionFormulationType } from '../types';
import IngredientModel from './ingredientModel';

export default class FormulationModel {
  id: number;

  active: boolean;

  name: string;

  legacyName: string;

  colorCode: string;

  colorHex: string;

  // Order refers to the Formulation's medically-valid
  // order relative to other formulas in the same group
  order: number;

  textColorHex: string;

  groupId: number;

  ingredients: IngredientModel[];

  isDarkSpotFormulation: boolean;

  hasHydroquinone: boolean;

  hasTretinoin: boolean;

  allergenicIngredients: IngredientAllergiesAndSideEffectsType[];

  hasSteroid: boolean;

  productId: number;

  constructor(data: PrescriptionFormulationType) {
    this.id = data.id;
    this.active = data.active;
    this.name = data.name;
    this.legacyName = data.legacyName;
    this.colorCode = data.colorCode;
    this.colorHex = data.colorHex;
    this.order = data.order;
    this.textColorHex = data.textColorHex;
    this.groupId = data.groupId;
    this.ingredients = data.ingredients.map(
      (ingredient) => new IngredientModel(ingredient),
    );
    this.isDarkSpotFormulation = data.isDarkSpotFormulation;
    this.hasHydroquinone = data.hasHydroquinone;
    this.hasTretinoin = data.hasTretinoin;
    this.allergenicIngredients = data.allergenicIngredients;
    this.hasSteroid = data.hasSteroid;
    this.productId = data.productId;
  }
}

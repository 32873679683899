import { useEffect } from 'react';
import { observer } from 'mobx-react';

import { performPageTrack } from 'jsx/utils/metrics';
import BaseUserStore from 'jsx/stores/baseUserStore';
import NullUserStore from 'jsx/auth/stores/nullUserStore';
import SignupUserStore from 'jsx/signup/stores/signupUser';
import useStores from 'jsx/utils/hooks/useStores';

// When refactoring userStore to hooks and import them specifically by path
// we'll need a way to distinguish between signup and auth user store since they are different
const DocumentTitle = ({ children: title }: { children: string }) => {
  const { userStore } = useStores() as {
    userStore: BaseUserStore | NullUserStore | SignupUserStore;
  };
  useEffect(() => {
    performPageTrack(userStore, title);
  });

  return null;
};

export default observer(DocumentTitle);

import { computed, makeObservable } from 'mobx';

import { IngredientGuideType } from 'jsx/types/ingredients';
import { TreatmentCategory } from 'jsx/types/treatmentCategories';

import type { PrescriptionPlanResource } from '../types';
import IngredientModel from './ingredientModel';
import PrescriptionModel from './prescriptionModel';

export default class PrescriptionPlanModel {
  id: number;

  productId: number;

  currentPrescriptionId: number | null;

  originalPrescriptionId: number;

  prescriptions: PrescriptionModel[];

  treatmentCategory: TreatmentCategory;

  constructor(data: PrescriptionPlanResource) {
    makeObservable(this);
    this.id = data.id;
    this.productId = data.productId;
    this.currentPrescriptionId = data.currentPrescriptionId;
    this.originalPrescriptionId = data.originalPrescriptionId;
    this.prescriptions = data.prescriptions.map(
      (prescription) => new PrescriptionModel(prescription),
    );
    this.treatmentCategory = data.treatmentCategory;
  }

  @computed
  get prescriptionCount(): number {
    return this.prescriptions.length;
  }

  @computed
  get isDarkSpot(): boolean {
    return this.containsDarkSpot;
  }

  @computed
  get isMultiRx(): boolean {
    return this.prescriptionCount > 1;
  }

  /**
   * For some designs we specifically want to show the name of the original prescription.
   */
  @computed
  get originalPrescription(): PrescriptionModel | undefined | null {
    return this.prescriptions.find(
      (prescription) => prescription.id === this.originalPrescriptionId,
    );
  }

  // Need to explicitly set the return type as null to help TS with PropTypes
  @computed
  get activePrescription(): PrescriptionModel | undefined | null {
    return this.prescriptions.find(
      (prescription) => prescription.id === this.currentPrescriptionId,
    );
  }

  /**
   * all prescr. including original, excluding active/current
   */
  @computed
  get dormantPrescriptions(): PrescriptionModel[] {
    return this.prescriptions.filter(
      (prescription) => prescription.id !== this.currentPrescriptionId,
    );
  }

  /**
   * all prescr. including active, excluding original
   */
  @computed
  get originalDormantPrescriptions(): PrescriptionModel[] {
    return this.prescriptions.filter(
      (prescription) => prescription.id !== this.originalPrescriptionId,
    );
  }

  @computed
  get hasTretinoin() {
    return !!this.prescriptions.find(
      (prescription) => !!prescription.formulation.hasTretinoin,
    );
  }

  @computed
  get allergenicIngredients(): string[] {
    const ingredients = this.prescriptions
      .map((prescription) => prescription.formulation.allergenicIngredients)
      .flat();

    // Use a set to get only unique values
    return [...new Set<string>(ingredients)];
  }

  @computed
  get hasHydroquinone() {
    return !!this.prescriptions.find(
      (prescription) => !!prescription.formulation.hasHydroquinone,
    );
  }

  @computed
  get hasSteroid() {
    return !!this.prescriptions.some(
      (prescription) => prescription.formulation.hasSteroid,
    );
  }

  @computed
  get containsDarkSpot() {
    return !!this.prescriptions.some(
      (prescription) => prescription.formulation.isDarkSpotFormulation,
    );
  }

  @computed
  get currentPrescriptionHasHydroquinone(): boolean {
    return !!this.activePrescription?.formulation.hasHydroquinone;
  }

  @computed
  get currentPrescriptionHasSteroid(): boolean {
    return !!this.activePrescription?.formulation.hasSteroid;
  }

  hasIngredient(ingredientName: IngredientGuideType): boolean {
    return !!this.prescriptions.find((prescription) =>
      prescription.formulation.ingredients.some(
        (ingredient: IngredientModel) =>
          ingredient.formattedKeyName === ingredientName,
      ),
    );
  }
}

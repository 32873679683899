import camelCase from 'lodash.camelcase';

import getIngredientInfo, { INGREDIENT } from 'jsx/utils/ingredients';

import { IngredientType } from '../types';

export default class IngredientModel {
  id: number;

  name: string;

  percentage: string;

  icon: JSX.Element | null;

  formattedKeyName: string;

  constructor(data: IngredientType) {
    const formattedKeyName = camelCase(data.name);
    const { icon } = getIngredientInfo(formattedKeyName as INGREDIENT);

    this.id = data.id;
    this.name = data.name;
    this.percentage = data.percentage;

    this.formattedKeyName = formattedKeyName;
    this.icon = icon ?? null;
  }
}

import React from 'react';
import { CameraIcon, InfoIcon, PhoneIcon } from 'radiance-ui/lib/icons';

/**
 * TODO: Should usage pull from `nudge_types` table in DB?
 */
export const NUDGE_TYPES = {
  needsPhotos: {
    uiOrder: 2,
    id: 1,
    type: 'needs_photos',
    name: 'Needs Photos',
    radianceIcon: (<CameraIcon />) as JSX.Element,
  },
  needsInfo: {
    uiOrder: 1,
    id: 2,
    type: 'needs_info',
    name: 'Needs Info',
    radianceIcon: (<InfoIcon />) as JSX.Element,
  },
  needsPhoneConsultation: {
    uiOrder: 3,
    id: 3,
    type: 'needs_phone_consultation',
    name: 'Needs Call',
    radianceIcon: (<PhoneIcon />) as JSX.Element,
  },
} as const;

export type NudgeTypeType = valueof<typeof NUDGE_TYPES>['type'];

/**
 * TODO: Should usage pull from `nudge_tags` table in DB?
 */
export const NUDGE_TAGS = {
  photoQuality: {
    id: 1,
    name: 'Quality',
    type: NUDGE_TYPES.needsPhotos.type,
  },
  photoPartial: {
    id: 2,
    name: 'Partial',
    type: NUDGE_TYPES.needsPhotos.type,
  },
  photoInaccurate: {
    id: 3,
    name: 'Inaccurate',
    type: NUDGE_TYPES.needsPhotos.type,
  },
  photoNotPatient: {
    id: 4,
    name: 'Not the Patient',
    type: NUDGE_TYPES.needsPhotos.type,
  },
  photoOther: {
    id: 5,
    name: 'Other',
    type: NUDGE_TYPES.needsPhotos.type,
  },
  infoDemographic: {
    id: 6,
    name: 'Demographic',
    type: NUDGE_TYPES.needsInfo.type,
  },
  infoMedicalHistory: {
    id: 7,
    name: 'Medical History',
    type: NUDGE_TYPES.needsInfo.type,
  },
  infoConcerns: {
    id: 8,
    name: 'Concerns',
    type: NUDGE_TYPES.needsInfo.type,
  },
  infoPreferences: {
    id: 9,
    name: 'Preferences',
    type: NUDGE_TYPES.needsInfo.type,
  },
  infoOther: {
    id: 10,
    name: 'Other',
    type: NUDGE_TYPES.needsInfo.type,
  },
  infoID: {
    id: 11,
    name: 'ID',
    type: NUDGE_TYPES.needsInfo.type,
  },
  phoneConsultationEstablishCare: {
    id: 12,
    name: 'Establish Care',
    type: NUDGE_TYPES.needsPhoneConsultation.type,
  },
  phoneConsultationExternalMed: {
    id: 13,
    name: 'External Med',
    type: NUDGE_TYPES.needsPhoneConsultation.type,
  },
} as const;

import { useCallback, useEffect, useState } from 'react';
import * as LaunchDarkly from 'launchdarkly-js-client-sdk';
import { LDContext } from 'launchdarkly-js-client-sdk';

import CONFIG from 'jsx/constants/config';
import type { Flag } from 'jsx/constants/featureFlagsAndMetrics';
import { LD_FLAGS } from 'jsx/constants/featureFlagsAndMetrics';

import { getQueryParameterValue } from './queryParameters';
import PatientModel from '../models/patient';
import { getLdUuidAndSetLdUuidCookie } from './ldUuid';
import { get } from './axiosWrapper';
import URLS from '../constants/urls';

interface CurologyContextData {
  userId: number;
  brand: string;
  deviceData: string;
  amplitudeDeviceId: string;
  eCommerceUser: boolean;
  isMinor: boolean;
  offerId: number;
  state: string;
}

interface UserContextResource {
  ldUuid: string;
  curologyContextData: CurologyContextData;
}

// Notion doc: https://www.notion.so/curology/LaunchDarkly-Codebase-Usage-951fa6eb639d4ceca0a3cfa346d1179c

let ldClient: LaunchDarkly.LDClient;
let ldClientReady = false;
const onReadyCallbacks: { (): void }[] = [];

const getContext = async (
  patient?: PatientModel | null,
  state?: string | null,
) => {
  const ldUuid = getLdUuidAndSetLdUuidCookie(patient);
  const contextPrefix = { kind: 'user', key: ldUuid };

  const userContextResource = (await get(URLS.api.featureUserContext()))
    .data as UserContextResource;
  const contextData = userContextResource?.curologyContextData;

  return state
    ? { ...contextPrefix, ...contextData, ...{ state } }
    : { ...contextPrefix, ...contextData };
};

export const initLd = async (
  patient?: PatientModel | null,
  state?: string | null,
) => {
  const clientSideId = window.CUROLOGY_ENV.LD_CLIENT_SIDE_ID;

  if (!clientSideId) {
    return;
  }
  const context = await getContext(patient, state);

  ldClient = LaunchDarkly.initialize(clientSideId, context as LDContext, {
    sendEventsOnlyForVariation: true,
  });

  ldClient.on('ready', () => {
    ldClientReady = true;

    onReadyCallbacks.forEach((onReadyCallback) => {
      onReadyCallback();
    });
  });
};

/**
 * There are two possible ways to override a LaunchDarkly flag value in a development environment.
 *
 *  1. Add a query parameter to your request with the flag name and override value. Example: for
 *      a flag named login, add `?login=true`
 *  2. Set the `value` of the LaunchDarkly flag to anything other than undefined in `jsx/constants/featureFlagsAndMetrics`
 *
 *  Note that a query param will override a value specified in `jsx/constants/featureFlagsAndMetrics`
 */
const getFlagOverride = <T>(flagName: Flag): T | undefined => {
  if (CONFIG.isProduction) {
    return undefined;
  }

  return (getQueryParameterValue(flagName) ??
    LD_FLAGS[flagName]) as unknown as T;
};

export const useFlag = <T>(flagName: Flag) => {
  const [flagValue, setFlagValue] = useState<T>();

  const evaluate = useCallback(() => {
    const override = getFlagOverride<T>(flagName) as unknown as T | undefined;

    if (override !== undefined) {
      setFlagValue(override);
      return;
    }

    const variation = ldClient.variationDetail(flagName);

    if (variation.reason?.kind === 'ERROR') {
      // eslint-disable-next-line no-console
      console.warn(
        `Error evaluating flag ${flagName}: ${variation.reason.errorKind}`,
      );
    }

    // Circular dependency with `trackEvent`, so we can just do the underlying implementation here.
    window.dataLayer.push({ event: 'Reset DataLayer', properties: null });
    window.dataLayer.push({
      event: 'Flag Evaluated',
      properties: {
        flag: flagName,
        variation: variation.value,
        reason: variation.reason,
      },
    });

    setFlagValue(variation.value as T);
  }, [flagName]);

  useEffect(() => {
    if (ldClientReady) {
      evaluate();
    } else {
      onReadyCallbacks.push(evaluate);
    }
  }, [evaluate, flagName]);

  return flagValue;
};

export const trackCustomFeatureFlagEvent = (eventName: string, data?: {}) => {
  if (ldClientReady) {
    ldClient.track(eventName, data);
  } else {
    onReadyCallbacks.push(() => {
      ldClient.track(eventName, data);
    });
  }
};

export const updateContext = async (
  patient?: PatientModel | null,
  state?: string | null,
) => {
  const context = await getContext(patient, state);
  if (ldClientReady) {
    await ldClient.identify(context);
  } else {
    onReadyCallbacks.push(() => {
      ldClient.identify(context);
    });
  }
};

export const flushLdClient = async () => {
  await ldClient.flush();
};

import { set } from 'mobx';
import camelcase from 'lodash.camelcase';

import convertToCamelCase from 'jsx/utils/convertCamelCase';

/**
 * Converting some models to explicitly set all properties in the constructor is inherently risky
 * without comprehensive test coverage. This helper method replicates the base model behavior of
 * using MobX set on all the properties passed in via the data object and setting it on the model
 */
export const setMissingPropertiesOnModel = <T>(
  model: T,
  modelData: { [key: string]: any },
) => {
  Object.entries(modelData).forEach(([key, value]) => {
    const modelHasKey = Object.prototype.hasOwnProperty.call(
      model,
      camelcase(key),
    );
    if (!modelHasKey) {
      // eslint-disable-next-line no-param-reassign
      set(model, convertToCamelCase({ [key]: value }));
    }
  });
};

import camelCase from 'lodash.camelcase';
import isPlainObject from 'lodash.isplainobject';
import type { CamelCasedPropertiesDeep } from 'type-fest';

function testIsPlainObject(object: unknown): object is Record<string, unknown> {
  return isPlainObject(object);
}

/**
 * We use `as` casting liberally in this function definition to get
 * around `any`/`unknown` issues that result from this API being
 * very permissible.
 *
 * @example
 *
 * const stringExample = convertToCamelCase('example_test' as const); // type is `'example_test'`
 *
 * const caseExample = convertToCamelCase({
 *  snake_case: true,
 *  camelCase: true,
 *  NESTED_CASE: { PascalCase: true },
 *  Array_Case: ['okay' as const, { nested_object: true }],
 * });
 *
 * // Type is `{ snakeCase: boolean; camelCase: boolean; nestedCase: { pascalCase: boolean; }; arrayCase: ("okay" | { nestedObject: boolean; })[]; }`
 */
// eslint-disable-next-line sonarjs/cognitive-complexity -- Since we call this function recursively it's difficult to chunk into smaller parts
export default function convertToCamelCase<T>(
  object: T,
): CamelCasedPropertiesDeep<T> {
  const camelCased: Record<string, unknown> = {};

  let tempValue;

  if (testIsPlainObject(object)) {
    Object.entries(object).forEach(([key, value]) => {
      tempValue = value;

      if (Array.isArray(value)) {
        tempValue = value.map((item) => convertToCamelCase(item));
      } else if (testIsPlainObject(value)) {
        tempValue = convertToCamelCase(value);
      }

      camelCased[camelCase(key)] = tempValue;
    });

    return camelCased as CamelCasedPropertiesDeep<T>;
  }

  if (Array.isArray(object)) {
    return object.map((value) => {
      if (Array.isArray(value)) {
        tempValue = value.map((item) => convertToCamelCase(item));
      } else if (testIsPlainObject(value)) {
        tempValue = convertToCamelCase(value);
      } else {
        tempValue = value;
      }

      return tempValue;
    }) as CamelCasedPropertiesDeep<T>;
  }

  return object as CamelCasedPropertiesDeep<T>;
}

import { observable, action, makeObservable } from 'mobx';

import useStores from 'jsx/utils/hooks/useStores';

const DEFAULT_OPTIONS = {
  modalContent: null,
  onCloseIconClick: () => null,
  title: '',
  showModal: false,
};

interface OptionsType {
  onCloseIconClick?: (() => void) | null;
  title?: string;
}

class DialogModalStore {
  @observable
  showModal = DEFAULT_OPTIONS.showModal;

  modalContent: JSX.Element | null = DEFAULT_OPTIONS.modalContent;

  @observable
  onCloseIconClick: () => void = DEFAULT_OPTIONS.onCloseIconClick;

  @observable
  title: string = DEFAULT_OPTIONS.title;

  constructor() {
    makeObservable(this);
  }

  @action
  openModal(content: JSX.Element, optionsParam: OptionsType = {}) {
    this.modalContent = content;
    this.onCloseIconClick =
      optionsParam.onCloseIconClick || DEFAULT_OPTIONS.onCloseIconClick;
    this.title = optionsParam.title || DEFAULT_OPTIONS.title;
    this.showModal = true;
  }

  @action
  closeModal = () => {
    // Checks and sets showModal=false to avoid calling this method recursively and infinitely
    // if passed dialogModalStore.closeModal to onCloseIconClick
    if (this.showModal && this.onCloseIconClick) {
      this.showModal = false;
      this.onCloseIconClick();
    }
    this.showModal = false;
    this.modalContent = DEFAULT_OPTIONS.modalContent;
    this.title = DEFAULT_OPTIONS.title;
  };
}

export const useDialogModalStore = (): {
  dialogModalStore: DialogModalStore;
} => {
  const { dialogModalStore } = useStores();

  return { dialogModalStore };
};

export default DialogModalStore;

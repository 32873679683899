import moment from 'moment';

/**
 * This method used to be the .format() method of a generic Model parent class used
 * liberally in our data models.
 *
 * We removed this class. But .format() is still called on some models.
 *
 * TODO: we should remove calls to .format() on data models.
 *
 * @deprecated
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function formatDate(key: string, format: string, dataObj: any) {
  const keys = key.split('.');
  let dateField = dataObj;
  keys.forEach((dataKey) => {
    dateField = dateField[dataKey];
  });

  if (!dateField) {
    return undefined;
  }

  if (!moment.isMoment(dateField)) {
    return dateField;
  }

  return dateField.format(format);
}

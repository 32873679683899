import { Moment } from 'moment';

import { momentOrNull } from 'jsx/utils/dateUtils';
import type {
  ShipmentFormulationType,
  InvoiceDetailsType,
} from 'jsx/dashboard/models/shipmentModel';
import type { TreatmentCategory } from 'jsx/types/treatmentCategories';
import SubscriptionItemModel, {
  SubscriptionItemData,
} from 'jsx/models/subscription/subscriptionItem';

export interface UpcomingShipmentData {
  shipmentItems: SubscriptionItemData[];
  shipsOnDate: string | null;
  estimatedDeliveryDate: string | null;
  paymentDetails: InvoiceDetailsType;
  earliestRescheduleDate: string | null;
  latestRescheduleDate: string | null;
  formulations?: ShipmentFormulationType[] | null;
  treatmentCategory?: TreatmentCategory | null;
  isOnetime: boolean;
}
export class UpcomingShipmentModel {
  constructor(data: UpcomingShipmentData) {
    this.shipmentItems = data.shipmentItems.map(
      (item) => new SubscriptionItemModel(item),
    );
    this.shipsOnDate = data.shipsOnDate;
    this.estimatedDeliveryDate = data.estimatedDeliveryDate;
    this.paymentDetails = data.paymentDetails;
    this.earliestRescheduleDate = momentOrNull(data.earliestRescheduleDate);
    this.latestRescheduleDate = momentOrNull(data.latestRescheduleDate);
    this.formulations = data.formulations;
    this.treatmentCategory = data.treatmentCategory;
    this.isOnetime = data.isOnetime;
  }

  readonly shipmentItems: SubscriptionItemModel[];

  readonly shipsOnDate: string | null;

  readonly estimatedDeliveryDate: string | null;

  readonly paymentDetails: InvoiceDetailsType;

  readonly earliestRescheduleDate: Moment | null;

  readonly latestRescheduleDate: Moment | null;

  readonly formulations?: ShipmentFormulationType[] | null;

  readonly treatmentCategory?: TreatmentCategory | null;

  readonly isOnetime: boolean;
}

import { computed, makeObservable, observable } from 'mobx';
import moment from 'moment';
import { v4 } from 'uuid';

import { CUROLOGY_ITEM_IDS, ITEM_IDS } from 'jsx/constants/items';
import { LIVE_CONSULTATION_STATUS } from 'jsx/constants/liveConsultations';
import type { BrandsType } from 'jsx/constants/brands';
import { momentOrNull } from 'jsx/utils/dateUtils';
import convertToCamelCase from 'jsx/utils/convertCamelCase';
import formatDate from 'jsx/dashboard/utils/formatDate';
import { Brand, REQUEST_BRAND } from 'jsx/utils/brand';

import PrescriptionPlanModel from './prescriptionPlans/prescriptionPlanModel';
import SubscriptionItemModel from '../subscription/subscriptionItem';
import {
  CheckoutPaymentDetails,
  ConsultStatusType,
  OfferType,
  PaymentMethodType,
  ProviderType,
  TopicalIngredientType,
  UserResourceType,
} from './types';
import { setMissingPropertiesOnModel } from '../utils';
import NudgeModel from '../nudge';
import { UpcomingShipmentModel } from './upcomingShipmentModel';
import { SubscriptionResourceType } from '../subscription';

export default class PatientModel {
  // eslint-disable-next-line sonarjs/cognitive-complexity
  constructor(data?: Readonly<UserResourceType>) {
    makeObservable(this);
    /**
     * We rely on instantiating a new PatientModel without data in our BaseUserStore.
     * Because of this pattern we need to explicitly set type-safe and type-consistent
     * default values for all the properties when we are not actually passing in data.
     */
    if (!data) {
      this.addresseeName = '';
      this.address1 = null;
      this.address2 = null;
      this.brand = null;
      this.canCustomizeSkinSubscriptionCadence = false;
      this.canOpenNewConsultation = null;
      this.canParticipateInRewardsProgram = false;
      this.cardLastFour = null;
      this.charityCreditBalance = null;
      this.checkoutPaymentDetails = null;
      this.city = null;
      this.cohortShortNames = [];
      this.dateOfBirth = null;
      this.dateSubscribed = null;
      this.deferredProductIds = [];
      this.discountBalance = null;
      this.discountType = null;
      this.email = '';
      this.emergencyContactName = null;
      this.emergencyContactPhone = null;
      this.emergencyContactRelationship = null;
      this.experiments = [];
      this.firstname = null;
      this.friendlyName = null;
      this.firstTopicalIngredient = null;
      this.gender = null;
      this.hasAbandonedCartDiscount = false;
      this.hasAllergies = false;
      this.hasAnsweredAllQuestions = false;
      this.hasAntiAgingPrimaryDiagnosis = false;
      this.hasCompletedAddress = false;
      this.hasCompletedConsultation = false;
      this.hasCompletedSignup = false;
      this.hasConsultationExperience = false;
      this.hasEligibility = false;
      this.hasFullPricedShipment = false;
      this.hasGuardian = false;
      this.hasNudge = false;
      this.hasOtcAccount = false;
      this.hasPendingCheckin = false;
      this.hasPendingOnetimeOrder = false;
      this.hasPendingPrescriptionItemRequest = false;
      this.hasPendingShipment = false;
      this.hasPendingShipmentReturns = false;
      this.hasPendingTrialOrder = false;
      this.hasPendingTrialShipment = false;
      this.hasReceivedShipment = false;
      this.hasReferralCode = false;
      this.hasShipmentHolds = false;
      this.hasShippedShipment = false;
      this.hasStaleSignupSurvey = false;
      this.hasSufficientPhotos = false;
      this.hasSufficientPhotosExceptId = false;
      this.hasUnseenResubscription = false;
      this.hasUnusedCancellationDiscount = false;
      this.hasUploadedPhoto = false;
      this.hasVerifiedEmail = false;
      this.id = parseFloat(`-${v4()}`);
      this.introOffer = null;
      this.idVerificationRequired = false;
      this.idVerificationStatus = null;
      this.isCancelled = false;
      this.isDelinquent = false;
      this.isChargedForConversations = false;
      this.isCurologyPatient = false;
      this.isEligibleForCancellationDiscount = false;
      this.isInFreeTrialPeriod = false;
      this.isInLiveConsultationState = false;
      this.isInPreconsult = false;
      this.isMinor = false;
      this.isOnPills = false;
      this.isPaused = false;
      this.isPaymentReCaptchaRequired = false;
      this.isPendingPillCallIn = false;
      this.isPregnantOrBreastfeeding = false;
      this.isReferred = false;
      this.isRescheduled = false;
      this.isSubscribed = false;
      this.is_subscribed = false;
      this.isTwoMonthFirstShipment = false;
      this.isUnbundlingEligible = false;
      this.isVip = false;
      this.lastname = null;
      this.launchDarklyUuid = null;
      this.livesInCalifornia = false;
      this.mostRecentShipmentBaseDate = null;
      this.mostRecentResubscription = null;
      this.needsToCompleteLiveConsultation = false;
      this.nextShipmentWillChargeAt = null;
      this.nudgeDetails = null;
      this.paymentMethod = null;
      this.pendingOrderItems = [];
      this.pendingSurveySlugs = [];
      this.pharmacyCity = null;
      this.pharmacyName = null;
      this.pharmacyPhone = null;
      this.pharmacyState = null;
      this.phone = null;
      this.phoneNumberIsConfirmed = null;
      this.phoneConsultStatus = null;
      this.postcardDay = 0;
      this.previousShipmentDate = null;
      this.previousPrescriptionItems = [];
      this.prescriptionPlans = null;
      this.provider = null;
      this.providerId = null;
      this.referralCreditBalance = null;
      this.rewardCreditBalance = null;
      this.requiresPaymentForCheckout = null;
      this.secondTopicalIngredient = null;
      this.sessionId = null;
      this.shipmentHoldReason = null;
      this.shipmentIsDelivered = null;
      this.shipmentTrackingUrl = null;
      this.shouldRefresh = false;
      this.state = null;
      this.subscriptions = [];
      this._subscriptionItems = [];
      this.supportCreditBalance = null;
      this.surveySlugs = [];
      this.thirdTopicalIngredient = null;
      this.userPostcardIdCreatedToday = null;
      this.videoConsultStatus = null;
      this.vipEndsAt = null;
      this.zipcode = null;
      this.upcomingShipment = null;
      this.upcomingShipments = [];
      this.usesMagicLink = false;
    } else {
      this.addresseeName = data.addresseeName;
      this.address1 = data.address1;
      this.address2 = data.address2;
      this.brand = data.brand;
      this.canCustomizeSkinSubscriptionCadence =
        data.canCustomizeSkinSubscriptionCadence;
      this.canOpenNewConsultation = data.canOpenNewConsultation;
      this.canParticipateInRewardsProgram = data.canParticipateInRewardsProgram;
      this.cardLastFour = data.cardLastFour;
      this.charityCreditBalance = data.charityCreditBalance;
      this.checkoutPaymentDetails = data.checkoutPaymentDetails;
      this.city = data.city;
      this.cohortShortNames = data.cohort_short_names;
      this.dateOfBirth = momentOrNull(data.date_of_birth);
      this.dateSubscribed = data.date_subscribed;
      this.deferredProductIds = data.deferredProductIds;
      this.discountBalance = data.discountBalance;
      this.discountType = data.discountType;
      this.email = data.email;
      this.emergencyContactName = data.emergency_contact_name;
      this.emergencyContactPhone = data.emergency_contact_phone;
      this.emergencyContactRelationship = data.emergency_contact_relationship;
      this.experiments = data.experiments;
      this.firstname = data.firstname;
      this.friendlyName = data.friendlyName;
      this.gender = data.gender;
      this.hasAbandonedCartDiscount = data.hasAbandonedCartDiscount;
      this.hasAllergies = data.hasAllergies;
      this.hasAnsweredAllQuestions = data.has_answered_all_questions;
      this.hasAntiAgingPrimaryDiagnosis = data.hasAntiAgingPrimaryDiagnosis;
      this.hasCompletedAddress = data.has_completed_address;
      this.hasCompletedConsultation = data.hasCompletedConsultation;
      this.hasCompletedSignup = data.has_completed_signup;
      this.hasConsultationExperience = data.has_consultation_experience;
      this.hasEligibility = data.has_eligibility;
      /**
       * TODO: Fully deprecate topical ingredient properties. We should remove it from
       * app/Http/Resources/Patient/UserResource.php since it is no longer used, but
       * if we do not set it explicitly we still set it in setMissingPropertiesOnModel(this, data);
       *
       * @see:  https://github.com/curology/PocketDerm/pull/12321#discussion_r582196287
       */
      this.firstTopicalIngredient = convertToCamelCase(
        data.first_topical_ingredient,
      );
      this.hasFullPricedShipment = data.hasFullPricedShipment;
      this.hasGuardian = data.has_guardian;
      this.hasNudge = data.hasNudge;
      this.hasOtcAccount = data.hasOtcAccount;
      this.hasPendingCheckin = data.has_pending_checkin;
      this.hasPendingOnetimeOrder = data.hasPendingOnetimeOrder;
      this.hasPendingPrescriptionItemRequest =
        data.hasPendingPrescriptionItemRequest;
      this.hasPendingShipment = data.has_pending_shipment;
      this.hasPendingShipmentReturns = data.hasPendingShipmentReturns;
      this.hasPendingTrialOrder = data.hasPendingTrialOrder;
      this.hasPendingTrialShipment = data.hasPendingTrialShipment;
      this.hasReceivedShipment = data.hasReceivedShipment;
      this.hasReferralCode = data.hasReferralCode;
      this.hasShipmentHolds = data.has_shipment_holds;
      this.hasShippedShipment = data.hasShippedShipment;
      this.hasStaleSignupSurvey = data.hasStaleSignupSurvey;
      this.hasSufficientPhotos = data.hasSufficientPhotos;
      this.hasSufficientPhotosExceptId = data.hasSufficientPhotosExceptId;
      this.hasUnseenResubscription = data.hasUnseenResubscription;
      this.hasUnusedCancellationDiscount = data.hasUnusedCancellationDiscount;
      this.hasUploadedPhoto = data.has_uploaded_photo;
      this.hasVerifiedEmail = data.hasVerifiedEmail;
      this.id = data.id;
      this.introOffer = data.introOffer;
      this.idVerificationRequired = data.idVerificationRequired;
      this.idVerificationStatus = data.idVerificationStatus;
      this.isCancelled = data.is_cancelled;
      this.isDelinquent = data.is_delinquent;
      this.isChargedForConversations = data.isChargedForConversations;
      this.isCurologyPatient = data.isCurologyPatient;
      this.isEligibleForCancellationDiscount =
        data.isEligibleForCancellationDiscount;
      this.isInFreeTrialPeriod = data.is_in_free_trial_period;
      this.isInLiveConsultationState = data.isInLiveConsultationState;
      this.isInPreconsult = data.isInPreconsult;
      this.isMinor = data.is_minor;
      this.isOnPills = data.is_on_pills;
      this.isPaused = data.isPaused;
      this.isPaymentReCaptchaRequired = data.isPaymentReCaptchaRequired;
      this.isPendingPillCallIn = data.is_pending_pill_call_in;
      this.isPregnantOrBreastfeeding = data.isPregnantOrBreastfeeding;
      this.isReferred = data.isReferred;
      this.isRescheduled = data.isRescheduled;
      this.isSubscribed = data.is_subscribed;
      this.is_subscribed = data.is_subscribed;
      this.isTwoMonthFirstShipment = data.isTwoMonthFirstShipment;
      this.isUnbundlingEligible = data.is_unbundling_eligible;
      this.isVip = data.is_vip;
      this.lastname = data.lastname;
      this.launchDarklyUuid = data.launch_darkly_uuid;
      this.livesInCalifornia = data.livesInCalifornia;
      this.mostRecentShipmentBaseDate = data.mostRecentShipmentBaseDate;
      this.mostRecentResubscription = data.most_recent_resubscription;
      this.needsToCompleteLiveConsultation =
        data.needsToCompleteLiveConsultation;
      this.nextShipmentWillChargeAt = momentOrNull(
        data.next_shipment_will_charge_at,
      );
      this.nudgeDetails = data.nudgeDetails
        ? new NudgeModel(data.nudgeDetails)
        : null;
      this.paymentMethod = convertToCamelCase(data.paymentMethod);
      this.pendingOrderItems = data.pendingOrderItems;
      this.pendingSurveySlugs = data.pendingSurveySlugs ?? [];
      this.pharmacyCity = data.pharmacy_city;
      this.pharmacyName = data.pharmacy_name;
      this.pharmacyPhone = data.pharmacy_phone;
      this.pharmacyState = data.pharmacy_state;
      this.phone = data.phone;
      this.phoneNumberIsConfirmed = data.phoneNumberIsConfirmed;
      this.phoneConsultStatus = data.phoneConsultStatus;
      this.postcardDay = data.postcard_day;
      this.previousShipmentDate = momentOrNull(data.previous_shipment_date);
      this.previousPrescriptionItems = data.previousPrescriptionItems
        ? data.previousPrescriptionItems.map(
            (subscriptionItem) => new SubscriptionItemModel(subscriptionItem),
          )
        : [];
      /**
       * TODO-MA: Can this be set in php artisan dev:getTestPatient seeders
       * for unit test data fidelity compatibility?
       */
      this.prescriptionPlans = data.prescriptionPlans
        ? data.prescriptionPlans.map(
            (prescriptionPlan) => new PrescriptionPlanModel(prescriptionPlan),
          )
        : [];
      this.provider = data.provider;
      this.providerId = data.provider_id;
      this.referralCreditBalance = data.referralCreditBalance;
      this.rewardCreditBalance = data.rewardCreditBalance;
      this.requiresPaymentForCheckout = data.requiresPaymentForCheckout;
      this.secondTopicalIngredient = convertToCamelCase(
        data.second_topical_ingredient,
      );
      this.sessionId = data.sessionId;
      this.shipmentHoldReason = data.shipment_hold_reason;
      this.shipmentIsDelivered = data.shipment_is_delivered;
      this.shipmentTrackingUrl = data.shipment_tracking_url;
      this.shouldRefresh = false;
      this.state = data.state;
      this.subscriptions = data.subscriptions;
      this._subscriptionItems = data.subscriptionItems
        ? data.subscriptionItems.map(
            (subscriptionItem) => new SubscriptionItemModel(subscriptionItem),
          )
        : [];
      this.supportCreditBalance = data.supportCreditBalance;
      this.surveySlugs = data.surveySlugs;
      this.thirdTopicalIngredient = convertToCamelCase(
        data.third_topical_ingredient,
      );
      this.userPostcardIdCreatedToday = data.user_postcard_id_created_today;
      this.videoConsultStatus = data.videoConsultStatus;
      this.vipEndsAt = momentOrNull(data.vipEndsAt);
      this.zipcode = data.zipcode;
      this.upcomingShipment = data.upcomingShipment
        ? new UpcomingShipmentModel(data.upcomingShipment)
        : null;
      this.upcomingShipments = data.upcomingShipments
        ? data.upcomingShipments.map(
            (upcomingShipment) => new UpcomingShipmentModel(upcomingShipment),
          )
        : [];
      this.usesMagicLink = data.usesMagicLink;

      setMissingPropertiesOnModel(this, data);
    }
  }

  /* eslint-disable lines-between-class-members */
  @observable
  addresseeName: string;
  @observable
  address1: string | null;
  @observable
  address2: string | null;
  @observable
  brand: BrandsType | null;
  @observable
  canCustomizeSkinSubscriptionCadence: boolean;
  @observable
  canOpenNewConsultation?: boolean | null | undefined;
  @observable
  canParticipateInRewardsProgram: boolean;
  @observable
  cardLastFour: string | null;
  @observable
  charityCreditBalance?: number | null | undefined;
  @observable
  checkoutPaymentDetails: CheckoutPaymentDetails | null;
  @observable
  cohortShortNames?: string[] | null | undefined;
  @observable
  city: string | null;
  @observable
  dateOfBirth: moment.Moment | null;
  @observable
  dateSubscribed: string | null;
  @observable
  deferredProductIds: number[];
  @observable
  discountBalance: string | null;
  @observable
  discountType: string | null;
  @observable
  email: string;
  @observable
  emergencyContactName: string | null;
  @observable
  emergencyContactPhone: string | null;
  @observable
  emergencyContactRelationship: string | null;
  @observable
  experiments: string[] | null | undefined;
  @observable
  firstname: string | null;
  @observable
  friendlyName: string | null;
  @observable
  firstTopicalIngredient: TopicalIngredientType | null;
  @observable
  gender: 'female' | 'male' | null;
  @observable
  hasAbandonedCartDiscount: boolean;
  @observable
  hasAllergies: boolean;
  @observable
  hasAnsweredAllQuestions: boolean;
  @observable
  hasAntiAgingPrimaryDiagnosis: boolean;
  @observable
  hasCompletedAddress: boolean;
  @observable
  hasCompletedConsultation: boolean;
  @observable
  hasCompletedSignup: boolean;
  @observable
  hasConsultationExperience: boolean;
  @observable
  hasEligibility: boolean;
  @observable
  hasGuardian: boolean;
  @observable
  hasNudge: boolean | null;
  @observable
  hasOtcAccount: boolean | null;
  @observable
  hasPendingCheckin: boolean;
  @observable
  hasPendingOnetimeOrder: boolean;
  @observable
  hasPendingPrescriptionItemRequest: boolean;
  @observable
  hasPendingShipment: boolean;
  @observable
  hasPendingShipmentReturns: boolean;
  @observable
  hasPendingTrialOrder: boolean;
  @observable
  hasPendingTrialShipment: boolean;
  @observable
  hasReceivedShipment: boolean;
  /**
   * Determine if the patient has an existing referral code _and_ is eligible for
   * the rewards program. Note that this will return false even if the user has a
   * referral but is not currently eligible — eg: agency transfer, upgraded to vip, etc.
   */
  @observable
  hasReferralCode: boolean;
  @observable
  hasFullPricedShipment: boolean;
  @observable
  hasShipmentHolds: boolean;
  @observable
  hasShippedShipment: boolean;
  @observable
  hasStaleSignupSurvey: boolean;
  @observable
  hasSufficientPhotos: boolean;
  @observable
  hasSufficientPhotosExceptId: boolean;
  @observable
  hasUnseenResubscription: boolean;
  @observable
  hasUnusedCancellationDiscount: boolean;
  @observable
  hasUploadedPhoto: boolean;
  @observable
  hasVerifiedEmail: boolean;
  @observable
  id: number | string;
  @observable
  introOffer: OfferType | null;
  @observable
  idVerificationRequired: boolean;
  @observable
  idVerificationStatus: string | null;
  @observable
  isCancelled: boolean;
  @observable
  isDelinquent: boolean;
  @observable
  isChargedForConversations: boolean;
  @observable
  isCurologyPatient: boolean;
  @observable
  isEligibleForCancellationDiscount: boolean;
  @observable
  isInPreconsult: boolean;
  @observable
  isInFreeTrialPeriod: boolean;
  @observable
  isInLiveConsultationState: boolean;
  @observable
  isMinor: boolean;
  @observable
  isOnPills: boolean;
  @observable
  isPaused: boolean;
  @observable
  isPaymentReCaptchaRequired: boolean;
  @observable
  isPendingPillCallIn: boolean;
  @observable
  isPregnantOrBreastfeeding: boolean;
  @observable
  isReferred: boolean;
  @observable
  isRescheduled: boolean;
  @observable
  isSubscribed: boolean;
  @observable
  is_subscribed: boolean;
  @observable
  isTwoMonthFirstShipment: boolean;
  @observable
  isUnbundlingEligible: boolean;
  @observable
  isVip: boolean;
  @observable
  lastname: string | null;
  @observable
  launchDarklyUuid: string | null;
  @observable
  livesInCalifornia: boolean;
  @observable
  mostRecentResubscription: string | null;
  @observable
  mostRecentShipmentBaseDate: string | null;
  @observable
  needsToCompleteLiveConsultation: boolean;
  @observable
  nextShipmentWillChargeAt: moment.Moment | null;
  @observable
  nudgeDetails: NudgeModel | null;
  @observable
  paymentMethod: PaymentMethodType | null;
  @observable
  pendingOrderItems: SubscriptionItemModel[];
  @observable
  pendingSurveySlugs: string[];
  @observable
  pharmacyCity: string | null;
  @observable
  pharmacyName: string | null;
  @observable
  pharmacyPhone: string | null;
  @observable
  pharmacyState: string | null;
  @observable
  phone: string | null;
  @observable
  phoneConsultStatus: ConsultStatusType | null;
  @observable
  phoneNumberIsConfirmed: boolean | null;
  @observable
  postcardDay: number;
  @observable
  previousShipmentDate: moment.Moment | null;
  @observable
  prescriptionPlans: PrescriptionPlanModel[] | null;
  @observable
  provider: ProviderType | null;
  @observable
  providerId: number | null;
  @observable
  referralCreditBalance: number | null;
  @observable
  rewardCreditBalance: number | null;
  @observable
  secondTopicalIngredient: TopicalIngredientType | null;
  @observable
  sessionId: string | null;
  @observable
  shipmentHoldReason: string | null;
  @observable
  shipmentIsDelivered: boolean | null;
  @observable
  shipmentTrackingUrl: string | null;
  @observable
  requiresPaymentForCheckout: boolean | null;
  @observable
  shouldRefresh: boolean;
  @observable
  state: string | null;
  @observable
  subscriptions: SubscriptionResourceType[];
  @observable
  _subscriptionItems: SubscriptionItemModel[];
  @observable
  supportCreditBalance: number | string | null;
  @observable
  surveySlugs: string[] | null;
  @observable
  thirdTopicalIngredient: TopicalIngredientType | null;
  @observable
  userPostcardIdCreatedToday: number | null;
  @observable
  videoConsultStatus: ConsultStatusType | null;
  @observable
  vipEndsAt: moment.Moment | null;
  @observable
  zipcode: string | null;
  /**
   * The next-most upcoming shipment, regardless of treatment category
   */
  @observable
  upcomingShipment: UpcomingShipmentModel | null;
  /**
   * All upcoming shipments, one per subscribed treatment category
   */
  @observable
  upcomingShipments: UpcomingShipmentModel[];
  @observable
  usesMagicLink: boolean;
  @observable
  previousPrescriptionItems: SubscriptionItemModel[];
  /* eslint-enable lines-between-class-members */

  @computed
  get fullName() {
    return `${this.firstname} ${this.lastname}`;
  }

  @computed
  get hasSubmittedFullName() {
    return !!this.firstname && !!this.lastname;
  }

  @computed
  get patientBrand() {
    return this.brand ? new Brand(this.brand as BrandsType) : REQUEST_BRAND;
  }

  @computed
  get isHydrated() {
    return !!this.email;
  }

  @computed
  get liveConsultStatus(): ConsultStatusType | null {
    return this.phoneConsultStatus || this.videoConsultStatus;
  }

  @computed
  get hasCompletedLiveConsult() {
    return this.liveConsultStatus === LIVE_CONSULTATION_STATUS.completed;
  }

  hasPendingSurvey = (surveySlug: string) => {
    const { pendingSurveySlugs } = this;

    return !!pendingSurveySlugs?.includes(surveySlug);
  };

  hasSurvey(surveySlug: string) {
    const { surveySlugs } = this;

    return !!surveySlugs && surveySlugs.includes(surveySlug);
  }

  @computed
  get nextShipmentAt() {
    if (!this.nextShipmentWillChargeAt) {
      return null;
    }

    return this.nextShipmentWillChargeAt;
  }

  /**
   * @deprecated
   */
  format(key: string, format: string) {
    return formatDate(key, format, this);
  }

  formatNextShipmentDate(format: string) {
    const today = moment().endOf('day').subtract(1, 'day');

    if (!this.nextShipmentAt) {
      return undefined;
    }

    if (this.nextShipmentAt.isAfter(today)) {
      return this.nextShipmentAt.format(format);
    }
    return moment().add(1, 'day').format(format);
  }

  @computed
  get canRescheduleShipment() {
    // Can't reschedule if already has pending shipment
    if (this.hasPendingShipment) {
      return false;
    }

    // Can't reschedule if have not yet received a shipment
    if (!moment(this.previousShipmentDate).isValid()) {
      return false;
    }

    /*
     * Can't reschedule if it has not yet been 5 weeks since subscription was activated and not vip
     * Can't reschedule if it has not yet been 5 weeks since last shipment and vip
     */
    const canRescheduleAfter = moment(this.dateSubscribed).add(5, 'weeks');
    return moment().isAfter(canRescheduleAfter);
  }

  @computed
  get activePrescriptions() {
    if (!this.prescriptionPlans || this.prescriptionPlans.length === 0) {
      return [];
    }

    return this.prescriptionPlans.map((plan) => plan.activePrescription);
  }

  @computed
  get hasPrescriptionWithDSF(): boolean {
    return !!this.prescriptionPlans?.some((plan) => plan.containsDarkSpot);
  }

  /** Returns the total balance of all credits (includes general and non-Rx only credits) */
  @computed
  get totalCreditBalance() {
    return (
      parseFloat(this.totalGeneralCreditBalance) +
      parseFloat(this.totalNonRxCreditBalance)
    ).toFixed(2);
  }

  /**
   * Returns the total balance of credits that apply to any products; excludes non-Rx only credits
   */
  @computed
  get totalGeneralCreditBalance() {
    const supportCreditBalance =
      typeof this.supportCreditBalance === 'string'
        ? parseFloat(this.supportCreditBalance)
        : Number(this.supportCreditBalance);
    const referralCreditBalance =
      typeof this.referralCreditBalance === 'string'
        ? parseFloat(this.referralCreditBalance)
        : Number(this.referralCreditBalance);
    const charityCreditBalance =
      typeof this.charityCreditBalance === 'string'
        ? parseFloat(this.charityCreditBalance)
        : Number(this.charityCreditBalance);

    return (
      supportCreditBalance +
      referralCreditBalance +
      charityCreditBalance
    ).toFixed(2);
  }

  /**
   * Returns the total balance of credits that apply to non-Rx products only
   */
  @computed
  get totalNonRxCreditBalance() {
    const rewardCreditBalance =
      typeof this.rewardCreditBalance === 'string'
        ? parseFloat(this.rewardCreditBalance)
        : Number(this.rewardCreditBalance);

    return rewardCreditBalance.toFixed(2);
  }

  /**
   * This only checks cohort names and not experiment names,
   * so we need to ensure that any cohort names we check are unique.
   */
  isInCohort = (cohortShortName: string) =>
    !!this.cohortShortNames && this.cohortShortNames.includes(cohortShortName);

  isInExperiment = (experimentShortName: string) =>
    !!this.experiments && this.experiments.includes(experimentShortName);

  @computed
  get hasDarkSpotFormula() {
    return !!this.prescriptionPlans?.some((plan) => plan.isDarkSpot);
  }

  @computed
  get hasFormulas() {
    return this.prescriptionPlans && this.prescriptionPlans.length !== 0;
  }

  @computed
  get hasMultiRx() {
    return (
      !!this.prescriptionPlans &&
      this.prescriptionPlans.some((plan) => plan.isMultiRx)
    );
  }

  @computed
  get hasBarrierBalm() {
    return !!this._subscriptionItems.find(
      (item) => item.itemId === CUROLOGY_ITEM_IDS.largeBarrierBalm,
    );
  }

  /**
   * @deprecated Use multi subscription aware helper calculateCanBuyNowBySubscription
   */
  @computed
  get canBuyNow() {
    const isDarkSpotEligible = !this.hasDarkSpotFormula;

    return !!(
      !this.isVip &&
      !this.hasShipmentHolds &&
      !this.isDelinquent &&
      !this.hasPendingShipment &&
      !this.hasPrescriptionWithDSF &&
      this.isSubscribed &&
      this.hasShippedShipment &&
      isDarkSpotEligible
    );
  }

  @computed
  get didPatientSubscribeWithinLast30Days() {
    return (
      !!this.dateSubscribed &&
      moment(this.dateSubscribed).isAfter(moment().subtract(30, 'days'))
    );
  }

  /**
   * We currently only show the guarantee to patients within 30 days of their subscription start date,
   * but in the event that we change that we currently de-couple the date logic from the conditional use
   */
  @computed
  get shouldSeeGuarantee() {
    return this.didPatientSubscribeWithinLast30Days;
  }

  isUnbundled(subscriptionItems: SubscriptionItemModel[]) {
    return (
      subscriptionItems.filter(
        (product) => product.isPrescription && !product.isDeferred,
      ).length === 0
    );
  }

  @computed
  get isIneligibleForDiscount() {
    return (
      !this.hasUnusedCancellationDiscount &&
      !this.isEligibleForCancellationDiscount
    );
  }

  isPrepaymentPlanEligible(subscriptionItems: SubscriptionItemModel[]) {
    const isCForFPRX =
      subscriptionItems.filter(
        (product) =>
          product.itemId === ITEM_IDS.largeCustomFormula ||
          product.itemId === ITEM_IDS.largeFutureFormula,
      ).length > 0;

    return (
      this.isCurologyPatient &&
      this.isSubscribed &&
      isCForFPRX &&
      !this.isVip &&
      !this.charityCreditBalance &&
      !this.isCancelled &&
      !this.isUnbundled(subscriptionItems)
    );
  }
}

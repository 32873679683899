import { useEffect, useRef } from 'react';

import PatientModel from 'jsx/models/patient/patient';
import CONFIG from 'jsx/constants/config';

declare global {
  interface Window {
    duet?: {
      open(): void;
      setUserId: (userId: string) => void;
      setMetadata: (metadata: {
        email?: string;
        name?: string;
        path?: string;
      }) => void;
      updateStyle: (style: { [key: string]: string | number }) => void;
    };
  }
}

export const useDecagonChatBot = (patient?: PatientModel) => {
  const brand = window.CUROLOGY_ENV.APP_BRAND ?? patient?.brand;
  const decagonScriptRef = useRef<HTMLScriptElement | null>(null);

  useEffect(() => {
    // we only want this to run once
    if (!!decagonScriptRef.current || !brand || CONFIG.isTestEnv) return;

    const script = document.createElement('script');
    script.src = `https://decagon.ai/loaders/${brand}.js`;
    script.async = true;
    decagonScriptRef.current = script;

    script.addEventListener('load', () => {
      if (patient) {
        window.duet?.setUserId(String(patient.id));
      }
      window.duet?.setMetadata({
        email: patient?.email,
        name: patient?.fullName,
        path: window.location.pathname,
      });

      window.duet?.updateStyle({
        bottom: '45px',
        // Allow modals to be above chatbot, otherwise, scrolling breaks in iOS.
        zIndex: 999994,
      });
    });

    document.body.appendChild(script);
  }, [brand, patient]);
};

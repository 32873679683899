import { observable, action, computed, makeObservable } from 'mobx';
import { v4 } from 'uuid';
import { ReactNode } from 'react';
import axiosStatic from 'axios';
import type { AxiosInstance } from 'axios';

import BaseStore from 'jsx/stores/baseStore';
import useStores from 'jsx/utils/hooks/useStores';
import { Initializable, InitializableType } from 'jsx/mixins';
import URLS from 'jsx/constants/urls';

const SECOND = 1000;

export type AlertTypes = 'default' | 'error' | 'success' | 'info';

interface AlertType {
  id: string;
  type: AlertTypes;
  duration: number | 'sticky';
  content: ReactNode;
  truncateText?: boolean | null;
  avatarSrc?: string | null;
  ctaContent?: ReactNode | null;
}

export type AlertDuration = number | 'sticky';

export type AddAlertType = (
  alertContent: ReactNode,
  alertType?: AlertTypes,
  duration?: AlertDuration,
  truncateText?: false | 'truncateText',
  avatarSrc?: string | null,
  ctaContent?: ReactNode | null,
) => void;

export interface AlertStoreType extends InitializableType {
  alertsMap: Map<string, AlertType>;
  alerts: AlertType[];
  onInitialize: () => Promise<void>;
  addServerFlash: () => Promise<void>;
  add: AddAlertType;
  removeAlert: (alertId: string) => void;
  clearAll: () => void;
}

export interface FlashMessageResource {
  type: 'success' | 'error' | 'info';
  content: string;
}

export class AlertStore
  extends Initializable(BaseStore)
  implements AlertStoreType
{
  @observable
  alertsMap: Map<string, AlertType> = new Map();

  axios: AxiosInstance;

  constructor(axios?: AxiosInstance) {
    super();

    makeObservable(this);

    this.axios = axios ?? axiosStatic;
  }

  @computed
  get alerts(): AlertType[] {
    return Array.from(this.alertsMap.values());
  }

  @action
  onInitialize = () => this.addServerFlash();

  @action
  async addServerFlash(): Promise<void> {
    // Note that we're not using the axiosWrapper here because it creates a
    // circular dependency, and we don't need any of the interceptors for
    // this particular request. No need for CSRF and we don't want to flash
    // an error if we're unable to retrieve the previous error messages.
    const response = await this.axios.get<{ data: FlashMessageResource[] }>(
      URLS.api.flashMessages(),
    );

    response.data.data.forEach(({ type, content }) => {
      const alertType =
        type === 'error' || type === 'success' ? type : 'default';

      this.add(content, alertType);
    });
  }

  /* eslint-disable default-param-last -- refactor to use named parameters for clarity */
  @action
  add: AddAlertType = (
    alertContent,
    alertType = 'error',
    duration = 4 * SECOND,
    truncateText = false,
    avatarSrc,
    ctaContent,
  ) => {
    // duration = 'sticky' will be dismissable alert
    const alertDuration = duration === 'sticky' ? duration : duration / SECOND;
    const id = `alert__${v4()}`;

    const alert = {
      id,
      type: alertType,
      duration: alertDuration,
      content: alertContent,
      truncateText: !!truncateText, // if 'truncateText' string passed cast to true
      avatarSrc,
      ctaContent,
    };

    if (alertContent) {
      this.alertsMap.set(alert.id, alert);
    }
  };
  /* eslint-enable default-param-last */

  @action
  removeAlert = (alertId: string): void => {
    this.alertsMap.delete(alertId);
  };

  @action
  clearAll = (): void => {
    this.alertsMap.clear();
  };
}

type useAlertStore = () => { alertStore: AlertStoreType };

export const useAlertStore: useAlertStore = () => {
  const { alertStore } = useStores();

  return { alertStore };
};

export default new AlertStore();
